import { lazy } from "react";

// project import
import PublicLayout from "layout/PublicLayout";
import Loadable from "components/Loadable";
import AboutComponent from "pages/public-pages/About/AboutComponent";
import CareersComponent from "pages/public-pages/Careers/CareersComponent";
import RecognitionComponent from "pages/public-pages/Recognition/RecognitionComponent";
import EventsComponent from "pages/public-pages/Events/EventsComponent";
import RectorateComponent from "pages/public-pages/About/RectorateComponent";
import StrategyComponent from "pages/public-pages/About/StrategyComponent";
import NewsComponent from "pages/public-pages/News/NewsComponent";
import NewsDetailsComponent from "pages/public-pages/News/NewsDetailsComponent";
import AssociationComponent from "pages/public-pages/Association/AssociationComponent";

// render - pages
const Homepage = Loadable(lazy(() => import("pages/public-pages/Homepage/Homepage")));

// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
  path: "/",
  element: <PublicLayout />,
  children: [
    {
      path: "/home",
      element: <Homepage />,
    },
    {
      path: "/about",
      element: <AboutComponent />,
    },
    {
      path: "/about/rectorate",
      element: <RectorateComponent />,
    },
    {
      path: "/about/strategy",
      element: <StrategyComponent />,
    },
    {
      path: "/association",
      element: <AssociationComponent />,
    },
    {
      path: "/events",
      element: <EventsComponent />,
    },
    {
      path: "/news",
      element: <NewsComponent />,
    },
    {
      path: "/news/details/:publicNewsId",
      element: <NewsDetailsComponent />,
    },
    {
      path: "/careers",
      element: <CareersComponent />,
    },
    {
      path: "/recognition",
      element: <RecognitionComponent />,
    },
  ],
};

export default PublicRoutes;
