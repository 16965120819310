import Axios from "axios";

export const EventTypeRepository = {
  fetchAllEventTypes: () => {
    return Axios.get(`/api/EventType/GetAllEventTypes`);
  },

  fetchEventTypeByID: (eventTypeId) => {
    return Axios.get(`/api/EventType/GetEventTypeById/${eventTypeId}`);
  },

  createEventType: (data) => {
    return Axios({
      url: `/api/EventType/CreateEventType`,
      method: "POST",
      data: data,
    });
  },

  updateEventType: (data, eventTypeId) => {
    return Axios({
      url: `/api/EventType/UpdateEventType/${eventTypeId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteEventType: (eventTypeId) => {
    return Axios.delete(`/api/EventType/DeleteEventType/${eventTypeId}`);
  },
};
