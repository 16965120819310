import Axios from "axios";

export const OccupationRepository = {
  fetchOccupationsBySearchParameters: (payload) => {
    return Axios({
      url: `/api/Occupation/GetOccupationsBySearchParameters`,
      method: "GET",
      params: {
        NameME: payload.nameME,
        NameEN: payload.nameEN,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchAllOccupations: () => {
    return Axios.get(`/api/Occupation/GetAllOccupations`);
  },

  fetchOccupationByID: (occupationId) => {
    return Axios.get(`/api/Occupation/GetOccupationById/${occupationId}`);
  },

  createOccupation: (data) => {
    return Axios({
      url: `/api/Occupation/CreateOccupation`,
      method: "POST",
      data: data,
    });
  },

  updateOccupation: (data, occupationId) => {
    return Axios({
      url: `/api/Occupation/UpdateOccupation/${occupationId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteOccupation: (occupationId) => {
    return Axios.delete(`/api/Occupation/DeleteOccupation/${occupationId}`);
  },
};
