import Axios from "axios";

export const ProfileSettingsRepository = {
  changeUserPassword: (data, userAccountId) => {
    return Axios({
      url: `/api/UserAccount/ChangeUserPassword/${userAccountId}`,
      method: "PUT",
      data: data,
    });
  },
};
