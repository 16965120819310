import {
  Button,
  Divider,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { notifyShowErrorMessage } from "store/reducers/app";

function InputUploadEventImage(props) {
  const { t } = useTranslation();
  const [myFiles, setMyFiles] = useState([]);
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null);

  const handlePreview = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setImagePreview(reader.result.split(",")[1]);
    };
  };

  const handleErrorNotification = () => {
    setMyFiles([]);
    dispatch(
      notifyShowErrorMessage({
        message: t("document.document_already_attached"),
      })
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    setMyFiles([...myFiles, ...acceptedFiles]);

    if (myFiles.length < 1) {
      acceptedFiles.map((file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          props.uploadedFiles.push(reader.result.split(",")[1]);
        };
      });
    }
  });

  const removeFile = (file) => () => {
    setImagePreview(null);
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    _.remove(props.uploadedFiles);
  };

  return (
    <Dropzone
      onDrop={onDrop}
      accept={{ "image/jpeg": [".jpeg", ".png", ".jpg"] }}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <section>
          {myFiles.length === 0 && (
            <div {...getRootProps()}>
              <input
                {...getInputProps()}
                accept=".pdf,.doc,.docx,.jpg"
                // multiple
              />
              <Button
                variant="outlined"
                // color="primary"
                component="span"
                style={{
                  width: "28%",
                  borderRadius: 4,
                  display: "flex",
                  flexDirection: "row",
                  // alignItems: "center",
                  padding: "8px",
                }}
                // onClick={handleFiles}
              >
                <PermMediaIcon sx={{ color: "#FFFFF", marginRight: 2 }} />
                <Typography variant="subtitle2" textTransform={"initial"}>
                  {t("events.image_upload")}
                </Typography>
              </Button>
            </div>
          )}

          <aside>
            <ul style={{ margin: 0, padding: 0 }}>
              {myFiles.length > 1
                ? handleErrorNotification()
                : myFiles.map((file, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 5,
                      }}
                    >
                      {handlePreview(file)}
                      <img
                        src={`data:image/jpeg;base64,${imagePreview}`}
                        style={{ width: 100, height: 100, marginRight: 3 }}
                        alt="preview-img"
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography variant="caption">
                          {`${file.name} - ${Math.ceil(file.size / 1000)} KB`}
                        </Typography>
                        <IconButton edge="end" onClick={removeFile(file)}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  ))}
            </ul>
          </aside>
        </section>
      )}
    </Dropzone>
  );
}

export default InputUploadEventImage;
