import Routes from "routes";
import ThemeCustomization from "themes";
import ScrollTop from "./components/ScrollTop";

//sass
import "../src/styles/app.scss";
import "App.css";

export default function App() {
  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}
