import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyShowErrorMessage, notifyShowSuccessMessage, rerender } from "store/reducers/app";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { Outlet } from "react-router-dom";
import { AuthService } from "auth/AuthService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InputUploadImage from "components/InputUploadImage";
import LoadingSpinner from "components/LoadingSpinner";
import moment from "moment";

//mui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  ListItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import PortraitIcon from "@mui/icons-material/Portrait";
import blank from "../../../../../assets/img/blank_avatar.jpg";

//repos
import { MembersRepository } from "pages/members/MembersRepository";
import { UserAccountsRepository } from "pages/userAccount/UserAccountsRepository";
import { GenderRepository } from "pages/administration/gender/GenderRepository";
import { CountryRepository } from "pages/administration/country/CountryRepository";
import { StudyLevelRepository } from "pages/administration/studyLevel/StudyLevelRepository";
import { OrganizationalUnitRepository } from "pages/administration/organizationalUnit/OrganizationalUnitRepository";
import { StudyProgramRepository } from "pages/administration/studyProgram/StudyProgramRepository";
import { OccupationRepository } from "pages/administration/occupation/OccupationRepository";

export default function MyProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [toggleEditUser, setToggleEditUser] = useState(false);

  const [userInfo, setUserInfo] = useState(null);
  const [values, setValues] = useState();

  const [countries, setCountries] = useState([]);
  const [genders, setGenders] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [studyLevels, setStudyLevels] = useState([]);
  const [organizationalUnits, setOrganizationalUnits] = useState([]);
  const [studyProgram, setStudyProgram] = useState([]);

  const [studyLevelId, setStudyLevelId] = useState();
  const [organizationalUnitId, setOrganizationalUnitId] = useState();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const userToken = AuthService.getDecodedUserJWT();
  const app_language = localStorage.getItem("lang-er");
  const { triggerRerender } = useSelector((state) => state.app);

  const handleEditUser = () => {
    setToggleEditUser((prev) => !prev);
    loadGenders();
    loadCountries();
    loadOccupations();

    if (userInfo !== null) {
      getMemberById();
      loadStudyLevel();
      loadOrganizationalUnit();
      loadStudyProgram();
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [triggerRerender]);

  const getUserInfo = () => {
    UserAccountsRepository.getUserSelfInfo()
      .then((res) => {
        setUserInfo(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getMemberById = () => {
    setLoading(true);
    MembersRepository.fetchMemberById(userInfo.member.memberId)
      .then((res) => {
        setValues(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          notifyShowErrorMessage({
            message: t("error"),
          })
        );
        setLoading(false);
      });
  };

  const loadStudyLevel = () => {
    StudyLevelRepository.fetchAllStudyLevels()
      .then((res) => {
        setStudyLevels(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadOrganizationalUnit = () => {
    OrganizationalUnitRepository.fetchAllOrganizationalUnits()
      .then((res) => {
        setOrganizationalUnits(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadStudyProgram = (studyLevelId, organizationalUnitId) => {
    StudyProgramRepository.fetchStudyProgramsBySearchParameters({
      studyLevelId: studyLevelId,
      organizationalUnitId: organizationalUnitId,
    })
      .then((res) => {
        setStudyProgram(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadOccupations = () => {
    OccupationRepository.fetchAllOccupations()
      .then((res) => {
        setOccupations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadCountries = () => {
    CountryRepository.fetchAll()
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log("err");
        }
      });
  };

  const loadGenders = () => {
    GenderRepository.fetchAllGenders()
      .then((res) => {
        setGenders(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log("err");
        }
      });
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    values,
    //  resolver: yupResolver(ValidateCountry())
    defaultValues: {
      gender: null,
      country: null,
      studyProgram: null,
      occupation: null,
    },
  });

  const updateUser = (values) => {
    setLoading(true);

    values.countryId = values.country.countryId;
    values.genderId = values.gender.genderId;
    values.occupationId = values.occupation.occupationId;
    values.dateOfBirth = moment(values.dateOfBirth).format("YYYY-MM-DD");
    values.studyProgramId = values.studyProgram.studyProgramId;
    values.userIcon = uploadedFiles.length === 0 ? values.userIcon : uploadedFiles[0];

    MembersRepository.updateMember(values, userInfo.member.memberId)
      .then((res) => {
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("success") }));
        setLoading(false);
        handleEditUser();
      })
      .catch((err) => {
        notifyShowErrorMessage({
          message: t("error"),
        });
        setLoading(false);
        handleEditUser();
      });
  };

  const renderAdminPanel = () => {
    return (
      <Paper
        elevation={3}
        sx={{
          borderRadius: "8px",
          padding: 2,
          marginBottom: 3,
        }}
      >
        <Box
          sx={{
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              flex: "0 0 10%",
              padding: 1.5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar alt="Icon" sx={{ width: 66, height: 66 }}>
              Admin
            </Avatar>
          </Box>
          <Box
            sx={{
              padding: 1.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography fontSize={"18px"} color={"#09375c"} fontWeight={500}>
                {userInfo?.firstName + " " + userInfo?.lastName}
              </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "#716c6c", fontWeight: 500 }}>
                {app_language === "sr"
                  ? userInfo?.organizationalUnit?.nameME
                  : app_language === "sr_kir"
                  ? userInfo?.organizationalUnit?.nameMECyrillic
                  : userInfo?.organizationalUnit?.nameEN}
              </Typography>
              <Typography variant="body2" sx={{ color: "#716c6c" }}>
                {app_language === "sr"
                  ? userInfo?.member?.occupation?.nameME
                  : app_language === "sr_kir"
                  ? userInfo?.member?.occupation?.nameMECyrillic
                  : userInfo?.member?.occupation?.nameEN}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : !userToken?.Role?.includes("Members") ? (
        renderAdminPanel()
      ) : (
        <>
          <Paper
            elevation={3}
            sx={{
              borderRadius: "8px",
              padding: 2,
              marginBottom: 3,
            }}
          >
            <Box
              sx={{
                borderRadius: "8px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  flex: "0 0 10%",
                  padding: 1.5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={
                    userInfo?.member?.userIcon
                      ? `data:image/jpeg;base64,${userInfo.member.userIcon}`
                      : blank
                  }
                  alt="Icon"
                  sx={{ width: 66, height: 66 }}
                />
              </Box>
              <Box
                sx={{
                  padding: 1.5,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography fontSize={"18px"} color={"#09375c"} fontWeight={500}>
                    {userInfo?.member?.firstName + " " + userInfo?.member?.lastName}
                  </Typography>
                </Box>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography sx={{ color: "#716c6c", fontWeight: 500 }}>
                    {userInfo?.member?.companyName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#716c6c" }}>
                    {app_language === "sr"
                      ? userInfo?.member?.occupation?.nameME
                      : app_language === "sr_kir"
                      ? userInfo?.member?.occupation?.nameMECyrillic
                      : userInfo?.member?.occupation?.nameEN}
                  </Typography>
                </Box>
              </Box>
              <Button
                variant={toggleEditUser ? "outlined" : "contained"}
                sx={{
                  alignSelf: "center",
                  marginLeft: "auto",
                }}
                onClick={handleEditUser}
              >
                {toggleEditUser ? t("back") : t("edit")}
              </Button>
            </Box>
          </Paper>

          {toggleEditUser ? (
            <Paper
              elevation={3}
              sx={{
                borderRadius: "8px",
                paddingY: 3,
                paddingX: 2,
                marginBottom: 3,
              }}
            >
              <form onSubmit={handleSubmit(updateUser)}>
                <Stack direction={"column"} marginBottom={2}>
                  <Stack direction={"row"} alignItems={"center"} spacing={1} marginBottom={2}>
                    <AccountBalanceIcon sx={{ color: "#363739" }} />
                    <Typography>{t("studyProgram.program")}</Typography>
                  </Stack>

                  {/* Study program edit  */}
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Box flex={"0 0 31%"}>
                      <Controller
                        name="studyProgram.studyLevel"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={studyLevels}
                            disabled={!studyLevels}
                            getOptionLabel={(option) =>
                              app_language === "sr"
                                ? option?.nameME
                                : app_language === "sr_kir"
                                ? option?.nameMECyrillic
                                : option?.nameEN
                            }
                            onChange={(event, item) => {
                              onChange(item);
                              setStudyLevelId(item.studyLevelId);
                            }}
                            value={value}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.studyLevelId === value.studyLevelId
                            }
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={t("studyProgram.studyLevel")}
                                sx={{
                                  ".MuiOutlinedInput-root": {
                                    padding: "4px",
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>

                    <Box flex={"0 0 31%"}>
                      <Controller
                        name="studyProgram.organizationalUnit"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={organizationalUnits}
                            disabled={!organizationalUnits || !studyLevelId}
                            getOptionLabel={(option) =>
                              app_language === "sr"
                                ? option?.nameME
                                : app_language === "sr_kir"
                                ? option?.nameMECyrillic
                                : option?.nameEN
                            }
                            onChange={(event, item) => {
                              onChange(item);
                              setOrganizationalUnitId(item.organizationalUnitId);
                              loadStudyProgram(studyLevelId, item.organizationalUnitId);
                            }}
                            value={value}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.organizationalUnitId === value.organizationalUnitId
                            }
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={t("organizationalUnit.org_unit")}
                                sx={{
                                  ".MuiOutlinedInput-root": {
                                    padding: "4px",
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>

                    <Box flex={"0 0 31%"}>
                      <Controller
                        name="studyProgram"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            options={studyProgram}
                            disabled={!studyProgram || !organizationalUnitId}
                            getOptionLabel={(option) =>
                              app_language === "sr"
                                ? option?.nameME
                                : app_language === "sr_kir"
                                ? option?.nameMECyrillic
                                : option?.nameEN
                            }
                            onChange={(event, item) => {
                              onChange(item);
                            }}
                            value={value}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.studyProgramId === value.studyProgramId
                            }
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={t("studyProgram.program")}
                                sx={{
                                  ".MuiOutlinedInput-root": {
                                    padding: "4px",
                                  },
                                }}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                  </Stack>
                </Stack>

                <Divider />

                {/* Job/company edit  */}
                <Box paddingY={2}>
                  <Stack direction={"row"} alignItems={"center"} spacing={1} marginBottom={2}>
                    <WorkIcon sx={{ color: "#363739" }} />
                    <Typography fontSize={"14px"}>{t("myProfile.job_position")}</Typography>
                  </Stack>
                  <Stack spacing={2}>
                    <TextField
                      label={t("myProfile.company_name")}
                      variant="outlined"
                      name="companyName"
                      sx={{ fontSize: 1, width: "50%" }}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("companyName")}
                    />
                    <Controller
                      name="occupation"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          options={occupations}
                          disabled={!occupations}
                          fullWidth
                          getOptionLabel={(option) =>
                            app_language === "sr"
                              ? option?.nameME
                              : app_language === "sr_kir"
                              ? option?.nameMECyrillic
                              : option?.nameEN
                          }
                          onChange={(event, item) => {
                            onChange(item);
                          }}
                          value={value}
                          getOptionSelected={(option, value) =>
                            value === undefined ||
                            value === "" ||
                            option.occupationId === value.occupationId
                          }
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              multiline
                              {...params}
                              label={t("myProfile.job_position")}
                              sx={{
                                ".MuiOutlinedInput-root": {
                                  padding: "3.9px",
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Stack>
                </Box>

                <Divider />

                {/* Personal data edit  */}
                <Box
                  className="personalData-stack"
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    paddingY: 2,
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} marginBottom={1} spacing={1}>
                    <Box
                      flexBasis={"3%"}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <PortraitIcon sx={{ color: "#363739" }} />
                    </Box>
                    <Box flexBasis={"15%"}>
                      <Typography fontSize={"14px"}>{t("myProfile.personal_data")}</Typography>
                    </Box>
                  </Stack>
                  <Stack direction={"column"}>
                    <Stack direction={"row"} marginBottom={1}>
                      <ListItem>
                        <TextField
                          label={t("myProfile.firstName")}
                          variant="outlined"
                          name="firstName"
                          required
                          sx={{ fontSize: 12 }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("firstName")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          label={t("myProfile.lastName")}
                          variant="outlined"
                          name="lastName"
                          required
                          sx={{ fontSize: 12 }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("lastName")}
                        />
                      </ListItem>
                    </Stack>
                    {values?.gender.genderId === 1 && (
                      <Stack direction={"row"} marginBottom={1}>
                        <ListItem>
                          <TextField
                            label={t("myProfile.maidenName")}
                            variant="outlined"
                            name="maidenName"
                            sx={{ width: "48%", fontSize: 12 }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...register("maidenName")}
                          />
                        </ListItem>
                      </Stack>
                    )}
                    <Box flexBasis={"100%"} display={"flex"} alignItems={"center"}>
                      <ListItem>
                        <Controller
                          name="dateOfBirth"
                          control={control}
                          defaultValue={null}
                          render={({ field, ...props }) => {
                            return (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label={t("dateOfBirth")}
                                  value={field.value || null}
                                  onChange={(date) => {
                                    field.onChange(date);
                                  }}
                                  inputFormat="dd.MM.yyyy"
                                  renderInput={(props) => (
                                    <TextField variant="standard" {...props} fullWidth />
                                  )}
                                />
                              </LocalizationProvider>
                            );
                          }}
                          {...register("dateOfBirth")}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          label={t("email")}
                          variant="outlined"
                          name="email"
                          sx={{ fontSize: 12 }}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register("email")}
                        />
                      </ListItem>
                    </Box>
                    <Box
                      flexBasis={"100%"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 2,
                      }}
                    >
                      <ListItem>
                        <Controller
                          name="country"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              fullWidth
                              options={countries}
                              disabled={!countries}
                              getOptionLabel={(option) => option?.nameME}
                              onChange={(event, item) => {
                                onChange(item);
                              }}
                              value={value}
                              getOptionSelected={(option, value) =>
                                value === undefined ||
                                value === "" ||
                                option.countryId === value.countryId
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("myProfile.country_of_residence")}
                                  sx={{
                                    ".MuiOutlinedInput-root": {
                                      padding: "4px",
                                    },
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                      </ListItem>
                      <ListItem>
                        <Controller
                          name="gender"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              fullWidth
                              options={genders}
                              disabled={!genders}
                              getOptionLabel={(option) => option?.nameME}
                              onChange={(event, item) => {
                                onChange(item);
                              }}
                              value={value}
                              getOptionSelected={(option, value) =>
                                value === undefined ||
                                value === "" ||
                                option.genderId === value.genderId
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("gender_name")}
                                  sx={{
                                    ".MuiOutlinedInput-root": {
                                      padding: "4px",
                                    },
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                      </ListItem>
                    </Box>
                    <Box sx={{ width: "30%", marginTop: 2, marginLeft: 2 }}>
                      <InputUploadImage uploadedFiles={uploadedFiles} />
                    </Box>
                  </Stack>
                </Box>

                <Stack direction="row" justifyContent="space-between" spacing={2}>
                  <Box />
                  <Button variant="contained" type="submit">
                    {t("save")}
                  </Button>
                </Stack>
              </form>
            </Paper>
          ) : (
            //Information
            <Paper
              elevation={3}
              sx={{
                borderRadius: "8px",
                paddingY: 3,
                paddingX: 2,
                marginBottom: 3,
              }}
            >
              {/* Study program info  */}
              <Box
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  paddingY: 2,
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Box
                    flexBasis={"5%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <AccountBalanceIcon sx={{ color: "#363739" }} />
                  </Box>
                  <Box flexBasis={"20%"}>
                    <Typography fontWeight={"bold"} fontSize={"14px"}>
                      {t("studyProgram.program")}
                    </Typography>
                  </Box>
                  <Stack
                    flexBasis={"70%"}
                    spacing={4}
                    display={"flex"}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Typography>
                      •{" "}
                      {app_language === "sr"
                        ? userInfo.member?.studyProgram?.studyLevel?.nameME
                        : app_language === "sr_kir"
                        ? userInfo.member?.studyProgram?.studyLevel?.nameMECyrillic
                        : userInfo.member?.studyProgram?.studyLevel?.nameEN}
                    </Typography>
                    <Typography>
                      •{" "}
                      {app_language === "sr"
                        ? userInfo.member?.studyProgram?.organizationalUnit?.nameME
                        : app_language === "sr_kir"
                        ? userInfo.member?.studyProgram?.organizationalUnit?.nameMECyrillic
                        : userInfo.member?.studyProgram?.organizationalUnit?.nameEN}
                    </Typography>
                    <Typography>
                      •{" "}
                      {app_language === "sr"
                        ? userInfo.member?.studyProgram?.nameME
                        : app_language === "sr_kir"
                        ? userInfo.member?.studyProgram?.nameMECyrillic
                        : userInfo.member?.studyProgram?.nameEN}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Divider />

              {/* Job title / company  */}
              <Box
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  paddingY: 2,
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Box
                    flexBasis={"5%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <WorkIcon sx={{ color: "#363739" }} />
                  </Box>
                  <Box flexBasis={"20%"}>
                    <Typography fontWeight={"bold"} fontSize={"14px"}>
                      {t("myProfile.job_position")}
                    </Typography>
                  </Box>
                  <Stack
                    flexBasis={"70%"}
                    spacing={4}
                    display={"flex"}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Typography>•{userInfo.member?.companyName}</Typography>
                    <Typography>
                      •{" "}
                      {app_language === "sr"
                        ? userInfo?.member?.occupation?.nameME
                        : app_language === "sr_kir"
                        ? userInfo?.member?.occupation?.nameMECyrillic
                        : userInfo?.member?.occupation?.nameEN}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Divider />

              {/* Personal data  */}
              <Box
                sx={{
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  paddingY: 2,
                }}
              >
                <Stack direction={"row"} alignItems={"center"}>
                  <Box
                    flexBasis={"5%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <PortraitIcon sx={{ color: "#363739" }} />
                  </Box>
                  <Box flexBasis={"20%"}>
                    <Typography fontWeight={"bold"} fontSize={"14px"}>
                      {t("myProfile.personal_data")}
                    </Typography>
                  </Box>
                  <Stack
                    flexBasis={"70%"}
                    spacing={4}
                    display={"flex"}
                    direction={"row"}
                    alignItems={"center"}
                  >
                    <Typography>
                      • {moment(userInfo?.member?.dateOfBirth).format("YYYY-MM-DD")}
                    </Typography>
                    <Typography>
                      •{" "}
                      {`${
                        localStorage.getItem("lang-er") === "sr"
                          ? userInfo?.member?.gender?.nameME + " pol"
                          : userInfo?.member?.gender?.nameEN
                      }`}
                    </Typography>
                    <Typography>• {userInfo?.member?.email}</Typography>
                  </Stack>
                </Stack>
              </Box>
            </Paper>
          )}
        </>
      )}
      <Outlet />
    </>
  );
}
