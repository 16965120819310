import React, { useEffect, useState, useTransition } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Stack,
  Tooltip,
  LinearProgress,
  TextField,
  InputAdornment,
  TablePagination,
  Pagination,
  Autocomplete,
  IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from "@mui/icons-material/People";
import blankEvent from "assets/img/blank_event.jpg";
import RefreshIcon from "@mui/icons-material/Refresh";

//event posters
import { useTranslation } from "react-i18next";
import { EventsRepository } from "./EventsRepository";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { EventTypeRepository } from "pages/administration/eventType/EventTypeRepository";

const EventComponent = ({
  eventId,
  eventName,
  eventDate,
  description,
  eventLocation,
  eventImage,
  eventMembers,
  numberOfGuest,
  isPublic,
}) => {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={3}
      style={{ padding: "16px", marginBottom: "16px" }}
      className="eventsComponent"
    >
      <Box display="flex" justifyContent={"space-between"} key={eventId}>
        <Box sx={{ flex: "0 0 28%", maxWidth: "0 0 28%" }}>
          <img
            className="eventImage"
            src={eventImage ? `data:image/jpeg;base64,${eventImage}` : blankEvent}
            alt="Event Image"
            // style={{ marginRight: "16px", width: 450, height: 150 }}
            style={{
              // width: 290,
              height: 150,
              marginRight: 10,
            }}
          />
        </Box>
        <Box className="eventDetails" sx={{ flex: "0 0 55%", maxWidth: "0 0 55%" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            // alignItems={"center"}
          >
            <Box sx={{ flex: "0 0 85%", maxWidth: "85%" }}>
              <Typography variant="subtitle1">{eventName}</Typography>
              <Divider style={{ margin: "8px 0" }} />
              <Stack direction={"row"} alignItems={"center"}>
                <TodayIcon sx={{ fontSize: 16, marginRight: 0.5 }} />
                <Typography variant="subtitle2">
                  <Moment format="DD.MM.YYYY">{eventDate}</Moment>
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <AccessTimeIcon sx={{ fontSize: 16, marginRight: 0.5 }} />
                <Typography variant="subtitle2">
                  <Moment format="HH:mm">{eventDate}</Moment>
                </Typography>
              </Stack>
              <Stack direction={"row"}>
                <LocationOnIcon sx={{ fontSize: 16, marginRight: 0.5 }} />
                <Typography variant="subtitle2">{eventLocation}</Typography>
              </Stack>
              {isPublic ? (
                <Typography fontSize={12}>{t("events.open_type")}</Typography>
              ) : (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    color: eventMembers?.length >= numberOfGuest ? "#d42121" : "green",
                    display: isPublic === false ? "flex" : "none",
                  }}
                >
                  <PeopleIcon
                    sx={{
                      fontSize: 21,
                      marginRight: 0.5,
                    }}
                  />
                  <Typography fontSize={12}>{eventMembers?.length}</Typography>
                  <Typography fontSize={12}>/{numberOfGuest}</Typography>
                </Stack>
              )}
            </Box>
            <Box
              sx={{
                flex: "0 0 12%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip title={"Detaljnije"}>
                <Link
                  to={`/app/events/${eventId}`}
                  style={{
                    textDecoration: "none",
                    color: "#09375c",
                    display: "inline",
                    height: 20,
                  }}
                >
                  <ArrowForwardIcon sx={{ fontSize: "30px" }} />
                </Link>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default function Events() {
  const { t } = useTranslation();

  const [events, setEvents] = useState();
  const [pagination, setPagination] = useState({ TotalCount: 0 });
  const [eventName, setEventName] = useState();
  const [eventDate, setEventDate] = useState();
  const [eventTypes, setEventTypes] = useState();
  const [eventTypeId, setEventTypeId] = useState();
  const [jobLocation, setJobLocation] = useState();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);

  const [loader, setLoader] = useState(false);
  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadEvents();
    loadEventTypes();
  }, [triggerRerender, page, size, eventName, eventDate, eventTypeId]);

  const loadEvents = () => {
    setLoader(true);
    EventsRepository.fetchEventsBySearchParameters({
      eventName: eventName,
      eventDate: eventDate,
      eventTypeId: eventTypeId,
      page: page,
      size: size,
    })
      .then((res) => {
        setEvents(res?.data);
        setPagination(JSON.parse(res.headers.x_pagination));
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setEvents({ totalCount: 0 });
        }
      });
  };

  const loadEventTypes = () => {
    EventTypeRepository.fetchAllEventTypes()
      .then((res) => {
        setEventTypes(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log("err");
        }
      });
  };

  const handleRefresh = (event, value) => {
    window.location.reload();
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      {loader && <LoadingSpinner />}
      <Paper elevation={3} sx={{ borderRadius: "8px" }}>
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            paddingBottom: 0.8,
            borderRadius: "8px",
            marginBottom: 3,
            display: events?.length > 0 ? "block" : "none",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <TextField
              label={t("events.name")}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTimeout(() => {
                  setEventName(e.target.value);
                  setPage(1);
                }, 1500);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t("events.date")}
                value={eventDate || null}
                onChange={(date) => {
                  date != null
                    ? setEventDate(moment(date).format("YYYY-MM-DD"))
                    : setEventDate(null);
                }}
                inputFormat="dd.MM.yyyy"
                renderInput={(props) => <TextField {...props} fullWidth />}
              />
            </LocalizationProvider>
            <Autocomplete
              fullWidth
              options={eventTypes}
              disabled={!eventTypes}
              getOptionLabel={(option) => option?.nameME}
              onChange={(event, item) => {
                setEventTypeId(item?.eventTypeId);
              }}
              getOptionSelected={(option, value) =>
                value === undefined || value === "" || option.eventTypeId === value.eventTypeId
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("events.event_type")}
                  sx={{
                    ".MuiOutlinedInput-root": {
                      padding: "4px",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Typography variant="body2" fontWeight="bold" color="textSecondary" marginTop="9px">
            {/* Ukupni poslovi: {jobPostings.length} */}
          </Typography>
        </Box>
      </Paper>

      {events?.length > 0 ? (
        events?.map((event, index) => <EventComponent key={index} {...event} />)
      ) : events ? (
        <Box
          component={Paper}
          padding={2}
          fontSize={"15px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography>{t("events.no_events")}!</Typography>
          <Tooltip title="Refresh" placement="top">
            <IconButton onClick={handleRefresh}>
              <RefreshIcon sx={{ color: "#09375c" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        ""
      )}

      <Box
        display={events?.length > 0 ? "flex" : "none"}
        justifyContent={"right"}
        alignItems={"center"}
        marginTop={1}
      >
        <Pagination
          color="primary"
          count={pagination?.TotalPages}
          page={page}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
}
