import { lazy } from "react";
import { Navigate } from "react-router-dom";

// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { AuthService } from "auth/AuthService";
import ForumComponent from "pages/forum/ForumComponent";
import CreateJobOpening from "pages/jobs/CreateJobOpening";
import EventsComponent from "pages/events/EventsComponent";
import CreateEventComponent from "pages/events/CreateEventComponent";
import MyProfile from "layout/MainLayout/Header/HeaderContent/Profile/MyProfile";
import EnterNewPasswordModal from "layout/MainLayout/Header/HeaderContent/Profile/EnterNewPasswordModal";
import ChatComponent from "components/Chat/ChatComponent";
import EventDetailsComponent from "pages/events/EventDetailsComponent";
import JobsComponent from "pages/jobs/JobsComponent";

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));

const NewsFeedComponent = Loadable(lazy(() => import("pages/news-feed/NewsFeedComponent")));

const MembersComponent = Loadable(lazy(() => import("pages/members/MembersComponent")));
const MemberComponent = Loadable(lazy(() => import("pages/members/MemberComponent")));

const RoleComponent = Loadable(lazy(() => import("pages/administration/role/RoleComponent")));
const RoleCreateComponent = Loadable(
  lazy(() => import("pages/administration/role/RoleCreateComponent"))
);
const RoleDeleteComponent = Loadable(
  lazy(() => import("pages/administration/role/RoleDeleteComponent"))
);
const RoleEditComponent = Loadable(
  lazy(() => import("pages/administration/role/RoleEditComponent"))
);

//groups
const GroupComponent = Loadable(lazy(() => import("pages/group/GroupComponent")));
const SingleGroupComponent = Loadable(lazy(() => import("pages/group/SingleGroupComponent")));
const GroupCreateComponent = Loadable(lazy(() => import("pages/group/GroupCreateComponent")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/app",
  element: <MainLayout />,
  children: [
    {
      path: "/app/dashboard",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,Deans,Mobilities,Projects,OrganizationAdministrator"
        >
          <DashboardDefault />
        </RequireAuth>
      ),
    },
    {
      path: "/app/my-profile",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MyProfile />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/my-profile/change-password",
          element: (
            <RequireAuth
              redirectTo="/app/login"
              hasAccess="Members,Administrators,Projects,OrganizationAdministrator"
            >
              <EnterNewPasswordModal />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/chats",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <ChatComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/groups",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <GroupComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/groups/create",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <GroupCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/groups/:groupId",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <SingleGroupComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/news-feed",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <NewsFeedComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/members",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MembersComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/members/:memberId",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MemberComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/forum",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <ForumComponent />
        </RequireAuth>
      ),
    },

    {
      path: "/app/admin/administration/roles",
      element: (
        <RequireAuth redirectTo="/app/login" hasAccess="Members,Administrators,Projects">
          <RoleComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/admin/administration/roles/create",
          element: (
            <RequireAuth redirectTo="/app/login" hasAccess="Members,Administrators,Projects">
              <RoleCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/admin/administration/roles/:roleId/edit",
          element: (
            <RequireAuth redirectTo="/app/login">
              <RoleEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/admin/administration/roles/:roleId/delete",
          element: (
            <RequireAuth redirectTo="/app/login">
              <RoleDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },
    {
      path: "/app/jobs",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <JobsComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/jobs/create",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <CreateJobOpening />
        </RequireAuth>
      ),
    },
    {
      path: "/app/events",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <EventsComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/events/:eventId",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <EventDetailsComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/events/create-event",
      element: (
        <RequireAuth
          redirectTo="/app/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <CreateEventComponent />
        </RequireAuth>
      ),
    },
  ],
};

function RequireAuth({ children, redirectTo, hasAccess }) {
  const data = AuthService.getDecodedUserJWT();

  if (!data) {
    return <Navigate to={redirectTo} replace />;
  }

  const hasAccessArr = hasAccess.split(",");
  const roles = data?.Role;
  const userHasRequiredRole = hasAccessArr.some((item) => roles?.includes(item));

  if (!userHasRequiredRole) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
}

export default MainRoutes;
