import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PublicNewsRepository } from "pages/public-news/PublicNewsRepository";
import Moment from "react-moment";
import LoadingSpinner from "components/LoadingSpinner";
import HTMLString from "react-html-string";
import ucgSmLogo from "assets/img/no_image.jpg";

const NewsComponent = () => {
  const { t } = useTranslation();

  const app_language = localStorage.getItem("lang-er");

  const [pagination, setPagination] = useState({ TotalCount: 0 });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [loading, setLoading] = useState(false);

  const [publicNewsPosts, setPublicNewsPosts] = useState();
  const [recentNewsPosts, setRecentNewsPosts] = useState();

  useEffect(() => {
    loadPublicNews();
    loadRecentPublicNews();
  }, [page]);

  const loadRecentPublicNews = () => {
    setLoading(true);
    PublicNewsRepository.fetchRecentPublicNews()
      .then((res) => {
        setRecentNewsPosts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadPublicNews = () => {
    setLoading(true);
    PublicNewsRepository.fetchPublicNews({ page: page, size: size })
      .then((res) => {
        setPublicNewsPosts(res.data);
        setPagination(JSON.parse(res.headers.x_pagination));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Grid container mt={10}>
        <Grid item md={1}></Grid>
        <Grid item md={8}>
          <Stack>
            <Box
              sx={{
                backgroundColor: "#09375c",
                width: "21%",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  padding: "5px 7px",
                  color: "white",
                  textAlign: "center",
                  borderBottom: "3.5px solid #09375c",
                }}
              >
                {t("menu.news_feed")}
              </Typography>
            </Box>
            <Divider sx={{ borderColor: "#09375c", borderWidth: 1.5, mr: 3 }} />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "stretch",
                paddingTop: 5,
              }}
            >
              {publicNewsPosts?.map((item) => {
                return (
                  <Card
                    variant="outlined"
                    key={item.publicNewsId}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: { xs: "0 0 100%", md: "0 0 300px" },
                      maxWidth: { xs: "0 0 100%", md: "0 0 300px" },
                      backgroundColor: "transparent",
                      marginBottom: 2,
                      marginRight: 2,
                    }}
                  >
                    <Link
                      to={`/news/details/${item?.publicNewsId}`}
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <CardHeader
                        title={
                          <Typography
                            variant="h5"
                            sx={{
                              ":hover": { color: "#09375c" },
                              fontSize: "0.875rem",
                              marginBottom: 1,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              height: 53,
                            }}
                          >
                            {app_language === "sr"
                              ? item?.titleMNELatin.length >= 100
                                ? item?.titleMNELatin.substring(0, 100) + "..."
                                : item?.titleMNELatin
                              : app_language === "sr_kir"
                              ? item?.titleMNECyrillic.length >= 100
                                ? item?.titleMNECyrillic.substring(0, 100) +
                                  "..."
                                : item?.titleMNECyrillic
                              : item?.titleEN.length >= 100
                              ? item?.titleEN.substring(0, 100) + "..."
                              : item?.titleEN}
                          </Typography>
                        }
                        subheader={
                          <Stack>
                            <Moment format="DD.MM.YYYY HH:mm">
                              {item?.createdDate}
                            </Moment>
                            <Box>
                              {app_language === "sr"
                                ? item?.organizationalUnit?.nameME
                                : app_language === "sr_kir"
                                ? item?.organizationalUnit?.nameMECyrillic
                                : item?.organizationalUnit?.nameEN}
                            </Box>
                          </Stack>
                        }
                      />
                      <CardMedia
                        component="img"
                        sx={{ maxHeight: "180px" }}
                        image={`data:image/jpeg;base64,${item?.image}`}
                        alt={item?.titleMNELatin}
                      />
                      {/* <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          paddingX: 1.5,
                          paddingTop: 1,
                          paddingBottom: 0,
                        }}
                      >
                        <Typography variant="caption" sx={{ color: "grey" }}>
                          <Moment format="DD.MM.YYYY HH:mm">
                            {item?.createdDate}
                          </Moment>
                        </Typography>
                        <Typography variant="caption" sx={{ color: "grey" }}>
                          {app_language === "sr"
                            ? item?.organizationalUnit?.nameME
                            : app_language === "sr_kir"
                            ? item?.organizationalUnit?.nameMECyrillic
                            : item?.organizationalUnit?.nameEN}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          sx={{
                            ":hover": { color: "#09375c" },
                            fontSize: "0.875rem",
                            marginBottom: 1,
                            height: 67,
                          }}
                        >
                          <HTMLString
                            html={
                              app_language === "sr"
                                ? item?.titleMNELatin.length >= 100
                                  ? item?.titleMNELatin.substring(0, 100) +
                                    "..."
                                  : item?.titleMNELatin
                                : app_language === "sr_kir"
                                ? item?.titleMNECyrillic.length >= 100
                                  ? item?.titleMNECyrillic.substring(0, 100) +
                                    "..."
                                  : item?.titleMNECyrillic
                                : item?.titleEN.length >= 100
                                ? item?.titleEN.substring(0, 100) + "..."
                                : item?.titleEN
                            }
                          />
                        </Typography>
                      </CardContent>
                      <img
                        src={`data:image/jpeg;base64,${item?.image}`}
                        style={{
                          width: "100%",
                          //height: 200,
                          marginTop: "auto",
                          marginBottom: "auto",
                          maxHeight: "220px",
                        }}
                        alt={item?.titleMNELatin}
                      /> */}
                    </Link>
                  </Card>
                );
              })}
            </Box>
            <Box sx={{ marginLeft: "auto", marginRight: 15, marginY: 5 }}>
              <Pagination
                color="primary"
                count={pagination?.TotalPages}
                page={page}
                onChange={handleChangePage}
              />
            </Box>
          </Stack>
        </Grid>
        {/* recent - last 5 news  */}
        <Grid item md={2} marginLeft={6}>
          <Box
            sx={{
              backgroundColor: "#09375c",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                padding: "5px 7px",
                color: "white",
                textAlign: "center",
                borderBottom: "3.5px solid #09375c",
              }}
            >
              {t("public_news.latest_news")}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 5 }}>
            {recentNewsPosts?.map((item) => {
              return (
                <Card
                  key={item.publicNewsId}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: "#09375c",
                      marginRight: 2,
                      borderRadius: 0,
                      fontSize: 15,
                      width: 30,
                      height: 30,
                    }}
                    src={!item?.organizationalUnit?.code ? ucgSmLogo : ""}
                  >
                    {item?.organizationalUnit?.code}
                  </Avatar>
                  <CardContent
                    sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}
                  >
                    <Typography
                      sx={{ fontSize: 14, marginBottom: 0 }}
                      color="text.secondary"
                    >
                      <Moment format="DD.MM.YYY">{item?.createdDate}</Moment>
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      variant="h5"
                      component="div"
                    >
                      {app_language === "sr"
                        ? item?.organizationalUnit?.nameME
                        : app_language === "sr_kir"
                        ? item?.organizationalUnit?.nameMECyrillic
                        : item?.organizationalUnit?.nameEN}
                    </Typography>
                    <Link
                      to={`/news/details/${item?.publicNewsId}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Typography
                        sx={{ mb: 1.5, fontSize: 14, fontWeight: "500" }}
                        color="#09375c"
                      >
                        <HTMLString
                          html={
                            app_language === "sr"
                              ? item?.titleMNELatin
                              : app_language === "sr_kir"
                              ? item?.titleMNECyrillic
                              : item?.titleEN
                          }
                        />
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              );
            })}
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </>
  );
};

export default NewsComponent;
