import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import bgImg from "../../../assets/img/eventsBg.jpg";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import { useTranslation } from "react-i18next";

const EventsComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
        }}
      />
      <Grid container sx={{ margin: { xs: 0, md: 3 } }}>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={7} sx={{ padding: { xs: 3, md: 0 } }}>
          <Typography
            mb={2}
            sx={{ width: { xs: "100%", md: "75%" }, fontStyle: "italic", textAlign: "justify" }}
          >
            {t("event.firstParagraph_Text")}
          </Typography>
          <Typography sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }} mb={3}>
            {t("event.secondParagraph_Text")}
          </Typography>
          <Typography sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }} mb={3}>
            {t("event.thirdParagraph_Text")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          mb={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 3, md: 0 },
          }}
        >
          <Typography variant="h3" sx={{ ml: { xs: 0.5, md: 2 } }} mb={2}>
            {t("homepage.events")}
          </Typography>
          <Divider component="li" />
          <List
            sx={{
              py: 0,
              width: "100%",
              maxWidth: { xs: "100%" },
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider",
              backgroundColor: "background.paper",
            }}
          >
            <ListItemButton>
              <ListItemText primary={t("event.item")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>
            <Divider variant="inset" component="li" />
            <ListItemButton>
              <ListItemText primary={t("event.item1")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>
            <Divider variant="middle" component="li" />
            <ListItemButton>
              <ListItemText primary={t("event.item2")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>

            <Divider variant="middle" component="li" />
            <ListItemButton>
              <ListItemText primary={t("event.item3")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </>
  );
};

export default EventsComponent;
