// material-ui
import { useMediaQuery, Container, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  return (
    <Container maxWidth="xl" align="center" sx={{ marginTop: "auto" }}>
      <Typography
        variant="subtitle2"
        color="common.white"
        component="span"
        textTransform={"uppercase"}
        marginTop={"auto"}
      >
        {`${t("footer.infinity")}`} &copy; {`2024`}
      </Typography>
    </Container>
  );
};

export default AuthFooter;
