import React, { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Typography,
  Avatar,
  Paper,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Tooltip,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import EventNoteIcon from "@mui/icons-material/EventNote";

//persons imgs
import AcademicCalendar from "./AcademicCalendar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { JobsRepository } from "pages/jobs/JobsRepository";
import { Link } from "react-router-dom";
import { MembersRepository } from "pages/members/MembersRepository";

const DrawerCalendar = () => {
  const { t } = useTranslation();

  const [showCalendar, setShowCalendar] = useState(true);
  const [recentJobs, setRecentJobs] = useState();
  const [recentMembers, setRecentMembers] = useState([]);

  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadRecentJobs();
    loadMembers();
  }, [triggerRerender]);

  const loadRecentJobs = () => {
    JobsRepository.fetchRecentJobs()
      .then((res) => {
        setRecentJobs(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setRecentJobs({ totalCount: 0 });
        }
      });
  };

  const loadMembers = () => {
    MembersRepository.fetchMembersBySearchParameters({
      page: 1,
      size: 5,
    })
      .then((res) => {
        setRecentMembers(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log(err);
        }
      });
  };

  const JobPost = ({ company, title, img, jobURL }) => (
    <Grid container spacing={2} alignItems={"center"}>
      <Grid item md={10}>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar src={img} style={{ backgroundColor: "#138AD9", marginRight: "8px" }}>
            {title[0]}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              {company}
            </Typography>
            <Typography variant="body2">{title}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Tooltip title={t("jobs.url")}>
          <a
            href={jobURL}
            target="_blank"
            style={{
              textDecoration: "none",
              color: "#138ad9",
              display: "inline",
              height: 20,
            }}
          >
            <LinkIcon sx={{ fontSize: "28px" }} />
          </a>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const legendItems = [
    { text: "Nastava", color: "lightblue" },
    { text: "Zavrsni i popravni ispiti", color: "lightcoral" },
    { text: "Praznici", color: "#a0d468" },
    { text: "Organizacija", color: "lightsalmon" },
    { text: "Humanitarna akcija", color: "red" },
    { text: "Usvajanje ocjena", color: "yellowgreen" },
    { text: "Kolektivni godisnji odmor", color: "darkblue" },
  ];

  return (
    <>
      <Box
        sx={{
          width: "30%",
        }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        marginTop={3}
        sx={{
          overflowY: "auto", // Allow the right-side content to scroll if needed
          // backgroundColor: "#F0F4F8",
          borderRadius: "8px",
          // width: "20%", // with Calendar
          // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          width={"100%"}
          marginBottom={3}
          sx={{
            "&.MuiDialogActions-root": {
              display: "none",
            },
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              gutterBottom
              textAlign={"center"}
              textTransform={"uppercase"}
              style={{ color: "#138AD9" }}
            >
              {t("academic_calendar")}
            </Typography>
            {showCalendar ? (
              <AcademicCalendar />
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {legendItems.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ padding: 1 }}>{item.text}</TableCell>
                        <TableCell sx={{ backgroundColor: item.color }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Button
              sx={{ textTransform: "initial" }}
              onClick={() => {
                setShowCalendar((prev) => !prev);
              }}
            >
              {showCalendar ? t("legend") : t("back")}
            </Button>
          </div>
        </Box>

        <Paper
          elevation={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            paddingTop: 10,
            marginBottom: "16px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: "#138AD9", textTransform: "uppercase" }}
          >
            <a
              href="https://www.ucg.ac.me/kalendar.php"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
              }}
              target="_blank"
              alt="ucg-kalendar"
            >
              <EventNoteIcon sx={{ marginRight: 1 }} />
              {t("ucg_calendar")}
            </a>
          </Typography>
        </Paper>

        {/* Recent Jobs */}
        <Paper
          elevation={3}
          style={{
            width: "100%",
            padding: "16px",
            marginBottom: "16px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: "#138AD9", textTransform: "uppercase" }}
          >
            {t("homepage.recent_jobs")}
          </Typography>

          {recentJobs?.map((job) => (
            <JobPost
              company={job.companyName}
              title={job.jobTitle}
              jobURL={job.jobURL}
              img={`data:image/jpeg;base64,${job?.jobLogo}`}
            />
          ))}
        </Paper>

        {/* Recent Members */}
        <Paper
          elevation={3}
          style={{
            width: "100%",
            padding: "16px",
            marginBottom: "16px",
            backgroundColor: "white",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: "#138AD9", textTransform: "uppercase" }}
          >
            {t("recent_members")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {recentMembers.map((item, idx) => {
              return (
                <Tooltip key={idx} title={`${item?.firstName} ${item?.lastName}`} placement="top">
                  <Link to={`/app/members/${item.memberId}`} style={{ textDecoration: "none" }}>
                    <Avatar
                      src={`data:image/jpeg;base64,${item?.userIcon}`}
                      style={{
                        backgroundColor: "#138AD9",
                        flex: "0 0 14%",
                        height: "40px",
                      }}
                    >
                      {item?.firstName[0]}
                    </Avatar>
                  </Link>
                </Tooltip>
              );
            })}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default DrawerCalendar;
