import { useState } from "react";
import { Box, Button, Grid, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import Events from "./Events";
import PendingEvents from "./PendingEvents";
import { AuthService } from "auth/AuthService";

export default function EventsComponent() {
  const { t } = useTranslation();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userToken = AuthService.getDecodedUserJWT();

  return (
    <Grid container>
      <Grid item md={12}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            direction: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider", width: "80%" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label={t("events.events")} {...a11yProps(0)} />
              {userToken?.Role.includes("Administrators") && (
                <Tab
                  label={t("events.pending_events")}
                  sx={{ textTransform: "initial" }}
                  {...a11yProps(1)}
                />
              )}
              {userToken?.Role.includes("OrganizationAdministrator") && (
                <Tab
                  label={t("events.pending_events")}
                  sx={{ textTransform: "initial" }}
                  {...a11yProps(1)}
                />
              )}
              {/* <Tab label={t("jobs.requested_groups")} {...a11yProps(1)} />
              <Tab label={t("jobs.other_groups")} {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <Box
            sx={{ width: "18%", display: "flex", justifyContent: "flex-end" }}
          >
            {/* <Stack direction="row" alignItems="center" spacing={0}> */}
            <Link
              to="/app/events/create-event"
              style={{ textDecoration: "none", marginLeft: "auto" }}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ borderRadius: 10 }}
              >
                {t("add")}
              </Button>
            </Link>
            {/* </Stack> */}
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          <Events />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PendingEvents />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <OtherGroups /> */}
        </TabPanel>
      </Grid>
    </Grid>
  );
}
