import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Link, Navigate } from "react-router-dom";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { EventsRepository } from "./EventsRepository";
import { notifyShowSuccessMessage, rerender } from "store/reducers/app";
import InputUploadEventImage from "components/InputUploadEventImage";
import { EventTypeRepository } from "pages/administration/eventType/EventTypeRepository";
import EventValidation from "./EventValidation";
import { yupResolver } from "@hookform/resolvers/yup";

export default function CreateEventComponent() {
  const { t } = useTranslation();

  const [eventImage, setEventImage] = useState([]);
  const [eventTypes, setEventTypes] = useState();
  const [isPublic, setIsPublic] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);

  const [redirectTo, setRedirectTo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadEventTypes();
  }, [triggerRerender]);

  const loadEventTypes = () => {
    EventTypeRepository.fetchAllEventTypes()
      .then((res) => {
        setEventTypes(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log("err");
        }
      });
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EventValidation()),
  });

  const createEvent = (values) => {
    showLoadingBar();

    //timezone
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    values.eventDate = new Date(values.eventDate - tzoffset)
      .toISOString()
      .slice(0, -1);
    values.eventImage = eventImage[0];
    values.eventTypeId = values.eventType.eventTypeId;
    values.isPublic = JSON.parse(isPublic);
    values.numberOfGuest = values.numberOfGuest
      ? JSON.parse(values.numberOfGuest)
      : null;

    EventsRepository.createEvent(values)
      .then((res) => {
        setRedirectTo(`/app/events`);
        dispatch(rerender());
        dispatch(
          notifyShowSuccessMessage({
            message: t("events.create_success"),
          })
        );
        hideLoadingBar();
      })
      .catch((err) => {
        hideLoadingBar();
      });
  };

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} />}
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item>
          <Link to={`/app/events`} style={{ display: "inline" }}>
            <Avatar sx={{ bgcolor: "white" }}>
              <ArrowBackIosNewIcon sx={{ color: "grey" }} />
            </Avatar>
          </Link>
        </Grid>
        <Grid item md={11}>
          <Typography variant="h5">{t("events.create")}</Typography>
        </Grid>
      </Grid>
      <Box component={Paper} padding={3} marginTop={2}>
        <form onSubmit={handleSubmit(createEvent)}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <TextField
                label={t("events.name")}
                fullWidth
                name="eventName"
                error={errors?.eventName}
                helperText={errors?.eventName?.message}
                {...register("eventName")}
              />
              <Controller
                name="eventType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    fullWidth
                    options={eventTypes}
                    disabled={!eventTypes}
                    getOptionLabel={(option) => option?.nameME}
                    onChange={(event, item) => {
                      onChange(item);
                    }}
                    value={value}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.eventTypeId === value.eventTypeId
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("events.event_type")}
                        error={errors?.eventType}
                        helperText={errors?.eventType?.message}
                        sx={{
                          ".MuiOutlinedInput-root": {
                            padding: "4px",
                          },
                        }}
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="eventDate"
                control={control}
                // defaultValue={moment()}
                defaultValue={null}
                render={({ field, ...props }) => {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label={t("events.date")}
                        value={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        inputFormat={"DD.MM.YYYY HH:mm"}
                        ampm={false}
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            error={errors?.eventDate}
                            helperText={errors?.eventDate?.message}
                            fullWidth
                          />
                        )}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              <TextField
                label={t("events.description")}
                fullWidth
                name="description"
                error={errors?.description}
                helperText={errors?.description?.message}
                {...register("description")}
              />
              <TextField
                label={t("events.location")}
                fullWidth
                name="eventLocation"
                error={errors?.eventLocation}
                helperText={errors?.eventLocation?.message}
                {...register("eventLocation")}
              />
              <Stack direction={"row"} spacing={2}>
                <Controller
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label={t("events.public")}
                          onClick={(e) => {
                            setIsPublic(true);
                            setIsPrivate(false);
                          }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label={t("events.private")}
                          onClick={(e) => {
                            setIsPublic(false);
                            setIsPrivate(true);
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  defaultValue={true}
                  name="isPublic"
                  control={control}
                />
                <TextField
                  label={t("events.number_of_guest")}
                  fullWidth
                  sx={{ display: isPrivate == true ? "block" : "none" }}
                  name="numberOfGuest"
                  {...register("numberOfGuest")}
                />
              </Stack>
              <Box>
                <InputUploadEventImage uploadedFiles={eventImage} />
              </Box>
            </Stack>
          </Grid>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            marginTop={3}
          >
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ textTransform: "initial" }}
            >
              {t("events.create_event")}
            </Button>
            <Link to={"/app/events"} style={{ textDecoration: "none" }}>
              <Button variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
            </Link>
          </Stack>
        </form>
      </Box>
    </>
  );
}
