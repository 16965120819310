import Axios from "axios";

export const OrganizationalUnitRepository = {
  fetchOrganizationalUnitsBySearchParameters: (payload) => {
    return Axios({
      url: `/api/OrganizationalUnit/GetOrganizationalUnitsBySearchParameters`,
      method: "GET",
      params: {
        NameME: payload.nameME,
        NameEN: payload.nameEN,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchAllOrganizationalUnits: () => {
    return Axios.get(`/api/OrganizationalUnit/GetAllOrganizationalUnits`);
  },

  fetchOrganizationalUnitByID: (organizationalUnitId) => {
    return Axios.get(
      `/api/OrganizationalUnit/GetOrganizationalUnitById/${organizationalUnitId}`
    );
  },

  createOrganizationalUnit: (data) => {
    return Axios({
      url: `/api/OrganizationalUnit/CreateOrganizationalUnit`,
      method: "POST",
      data: data,
    });
  },

  updateOrganizationalUnit: (data, organizationalUnitId) => {
    return Axios({
      url: `/api/OrganizationalUnit/UpdateOrganizationalUnit/${organizationalUnitId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteOrganizationalUnit: (organizationalUnitId) => {
    return Axios.delete(
      `/api/OrganizationalUnit/DeleteOrganizationalUnit/${organizationalUnitId}`
    );
  },
};
