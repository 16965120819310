import { Box, Grid, Typography } from "@mui/material";
import LoadingSpinner from "components/LoadingSpinner";
import { PublicNewsRepository } from "pages/public-news/PublicNewsRepository";
import React, { useEffect, useState } from "react";
import HTMLString from "react-html-string";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const NewsDetailsComponent = () => {
  const app_language = localStorage.getItem("lang-er");
  const params = useParams();
  const [loading, setLoading] = useState(false);

  const [newsDetails, setNewsDetails] = useState();
  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadNewsDetails(params.publicNewsId);
  }, [triggerRerender]);

  const loadNewsDetails = (id) => {
    setLoading(true);
    PublicNewsRepository.fetchById(id)
      .then((res) => {
        setNewsDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Box xs={12} md={5} mt={3} sx={{ display: "flex", flexDirection: "column", width: "75%" }}>
          <Typography
            variant="h1"
            mb={2}
            sx={{
              alignSelf: "flex-start !important",
              borderBottom: "3.5px solid #09375c",
              width: "100%",
            }}
          >
            <HTMLString
              html={
                app_language === "sr"
                  ? newsDetails?.titleMNELatin
                  : app_language === "sr_kir"
                  ? newsDetails?.titleMNECyrillic
                  : newsDetails?.titleEN
              }
            />
          </Typography>
          <Typography>
            <Moment format="DD.MM.YYYY HH:mm">{newsDetails?.createdDate}</Moment>
          </Typography>
        </Box>
        <Box xs={12} md={5} sx={{ padding: { xs: 1.5, md: 0, width: "75%" } }} mb={3}>
          <img
            src={`data:image/jpeg;base64,${newsDetails?.image}`}
            style={{
              maxHeight: "400px",
              maxWidth: "70%",
              float: "left",
              marginRight: "15px",
              marginTop: "5px",
            }}
            alt=""
          />
          <Typography variant="body1" sx={{ fontSize: "18px", textAlign: "justify" }}>
            <HTMLString
              html={
                app_language === "sr"
                  ? newsDetails?.contentMNELatin
                  : app_language === "sr_kir"
                  ? newsDetails?.contentMNECyrillic
                  : newsDetails?.contentEN
              }
            />
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default NewsDetailsComponent;
