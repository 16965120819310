import * as yup from "yup";
import { useTranslation } from "react-i18next";

export default function ProfileSettingsValidation() {
  const { t } = useTranslation();

  return yup.object().shape({
    oldPassword: yup.string().required(t("users.old_password") + t("required_field")),
    newPassword: yup.string().required(t("users.new_password") + t("required_field")),
    confirmNewPassword: yup
      .string()
      .required(t("users.confirm_new_password") + t("required_field")),
  });
}
