import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

// assets
import DashboardIcon from "@mui/icons-material/Dashboard";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import KeyIcon from "@mui/icons-material/Key";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout, handleToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleDashboard = () => {
    navigate("/app/my-profile");
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleChangePassword = () => {
    navigate("/app/my-profile/change-password");
  };

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          color: theme.palette.grey[500],
        },
      }}
    >
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => {
          handleListItemClick(event, 1);
          handleDashboard();
          handleToggle();
        }}
      >
        <ListItemIcon>
          <DashboardIcon sx={{ color: "#09375c" }} />
        </ListItemIcon>
        <ListItemText primary={t("my_profile")} sx={{ color: "#09375c" }} />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 2} onClick={handleChangePassword}>
        <ListItemIcon>
          <KeyIcon sx={{ color: "#09375c" }} />
        </ListItemIcon>
        <ListItemText primary={t("users.change_password")} sx={{ color: "#09375c" }} />
      </ListItemButton>

      <ListItemButton selected={selectedIndex === 3} onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={t("logout")} />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
