import NewspaperIcon from "@mui/icons-material/Newspaper";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import FeedIcon from "@mui/icons-material/Feed";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GroupMemberRepository } from "pages/group/GroupMemberRepository";
import { useSelector } from "react-redux";

export default function Pages() {
  const { t } = useTranslation();
  const { triggerRerender } = useSelector((state) => state.app);
  const app_language = localStorage.getItem("lang-er");
  const [myGroups, setMyGroups] = useState();

  useEffect(() => {
    loadMyGroups();
  }, [triggerRerender]);

  const loadMyGroups = () => {
    GroupMemberRepository.fetchMyGroups(0)
      .then((res) => {
        setMyGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    id: "group-administration",
    type: "group",
    children: [
      {
        id: "news-feed",
        title: t("menu.news_feed"),
        type: "item",
        url: "/app/news-feed",
        icon: NewspaperIcon,
        hasAccess: ["Administrators", "Members", "OrganizationAdministrator"],
      },
      {
        id: "users",
        title: t("menu.users"),
        type: "item",
        url: "/app/admin/users",
        icon: SupervisedUserCircleIcon,
        hasAccess: ["Administrators", "OrganizationAdministrator"],
      },
      {
        id: "members",
        title: t("menu.members"),
        type: "item",
        url: "/app/members",
        icon: GroupIcon,
        hasAccess: ["Administrators", "Members", "OrganizationAdministrator"],
      },
      {
        id: "groups",
        title: t("group.multiple"),
        type: "item",
        url: "/app/groups",
        icon: GroupsIcon,
        hasAccess: ["Administrators", "Members", "OrganizationAdministrator"],
      },
      // {
      //   id: "groups",
      //   title: t("group.multiple"),
      //   type: "collapse",
      //   url: "",
      //   icon: GroupsIcon,
      //   hasAccess: ["Administrators", "Members"],
      //   children: myGroups
      //     ? myGroups?.map((item) => {
      //         return {
      //           id: item.group?.groupId,
      //           title:
      //             app_language === "sr"
      //               ? item.group?.nameME
      //               : item.group?.nameEN,
      //           type: "children",
      //           url: `/app/groups/${item.group?.groupId}`,
      //           hasAccess: ["Administrators", "Members"],
      //         };
      //       })
      //     : [],
      // },
      // {
      //   id: "jobs",
      //   title: t("menu.jobs"),
      //   type: "collapse",
      //   url: "",
      //   icon: WorkIcon,
      //   hasAccess: ["Administrators", "Members", "Projects", "Mobilities"],
      //   children: [
      //     {
      //       id: "openings",
      //       title: t("menu.job_ads"),
      //       type: "children",
      //       url: "/app/jobs/openings",
      //       hasAccess: ["Administrators", "Members"],
      //     },
      //     {
      //       id: "create",
      //       title: t("menu.create_job_ad"),
      //       type: "children",
      //       url: "/app/jobs/create",
      //       hasAccess: ["Administrators", "Members"],
      //     },
      //   ],
      // },
      {
        id: "jobs",
        title: t("menu.jobs"),
        type: "item",
        url: "/app/jobs",
        icon: WorkIcon,
        hasAccess: ["Administrators", "Members", "OrganizationAdministrator"],
      },
      // {
      //   id: "events",
      //   title: t("menu.events"),
      //   type: "collapse",
      //   url: "",
      //   icon: CalendarMonthIcon,
      //   hasAccess: ["Administrators", "Members", "Projects", "Mobilities"],
      //   children: [
      //     {
      //       id: "view-events",
      //       title: t("menu.events_notifications"),
      //       type: "children",
      //       url: "/app/events/view-events",
      //       hasAccess: ["Administrators", "Members"],
      //     },
      //     {
      //       id: "create-event",
      //       title: t("menu.create_event"),
      //       type: "children",
      //       url: "/app/events/create-event",
      //       hasAccess: ["Administrators", "Members"],
      //     },
      //   ],
      // },
      {
        id: "events",
        title: t("menu.events"),
        type: "item",
        url: "/app/events",
        icon: CalendarMonthIcon,
        hasAccess: ["Administrators", "Members", "OrganizationAdministrator"],
      },
      // {
      //   id: "userManagement",
      //   title: t("menu.userManagement"),
      //   type: "item",
      //   url: "/app/userManagement",
      //   icon: SupervisedUserCircleIcon,
      //   hasAccess: ["Administrators", "Members"],
      // },
      {
        id: "administration",
        title: t("menu.administration"),
        type: "collapse",
        url: "",
        icon: SettingsIcon,
        hasAccess: ["Administrators", "OrganizationAdministrator"],
        children: [
          {
            id: "organizational-units",
            title: t("menu.organizationalUnits"),
            type: "children",
            url: "/app/admin/administration/organizational-units",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "study-programs",
            title: t("menu.studyPrograms"),
            type: "children",
            url: "/app/admin/administration/study-programs",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "study-levels",
            title: t("menu.studyLevels"),
            type: "children",
            url: "/app/admin/administration/study-levels",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "countries",
            title: t("menu.countries"),
            type: "children",
            url: "/app/admin/administration/countries",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "genders",
            title: t("menu.genders"),
            type: "children",
            url: "/app/admin/administration/genders",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "member-types",
            title: t("menu.member_type"),
            type: "children",
            url: "/app/admin/administration/member-types",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "occupations",
            title: t("menu.occupations"),
            type: "children",
            url: "/app/admin/administration/occupations",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
          {
            id: "event-types",
            title: t("menu.event_types"),
            type: "children",
            url: "/app/admin/administration/event-types",
            hasAccess: ["Administrators", "OrganizationAdministrator"],
          },
        ],
      },
      {
        id: "public-news",
        title: t("public_news.news"),
        type: "item",
        url: "/app/admin/public-news",
        icon: FeedIcon,
        hasAccess: ["Administrators", "OrganizationAdministrator"],
      },
    ],
  };
}
