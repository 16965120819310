const ForumComponent = () => {
  console.log(`react snippet works!`);

  return (
    <>
      <p>FORUM</p>
    </>
  );
};

export default ForumComponent;
