import Axios from "axios";

export const GroupMemberRepository = {
  //status
  //0 -> getAllMyGroups
  //1 -> getMyPendingGroups
  fetchMyGroups: (status) => {
    return Axios.get(`/api/GroupMember/GetMyGroups/${status}`);
  },

  fetchGroupMembersBySearchParameters: (payload) => {
    return Axios({
      url: `/api/GroupMember/GetGroupMembersBySearchParameters`,
      method: "GET",
      params: {
        GroupId: payload.groupId,
        Status: payload.status,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  createGroupMember: (data) => {
    return Axios({
      url: `/api/GroupMember/CreateGroupMember`,
      method: "POST",
      data: data,
    });
  },

  deleteGroupMember: (groupMemberId) => {
    return Axios.delete(`/api/GroupMember/DeleteGroupMember/${groupMemberId}`);
  },

  approveMember: (groupMemberId) => {
    return Axios({
      url: `/api/GroupMember/ApproveMemberGroup/${groupMemberId}`,
      method: "PUT",
    });
  },

  rejectMember: (groupMemberId) => {
    return Axios({
      url: `/api/GroupMember/RejectMemberGroup/${groupMemberId}`,
      method: "PUT",
    });
  },
};
