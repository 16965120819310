import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import httpService from "./app/api/HttpClient";
import { Provider as ReduxProvider } from "react-redux";
import { StrictMode } from "react";
import { store } from "store";
import "./i18n/config";

const container = document.getElementById("root");
const root = createRoot(container);

httpService.setupInterceptors(store);

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
