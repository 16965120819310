import Axios from "axios";

export const ChatRepository = {
  //Chat
  fetchAllChats: () => {
    return Axios.get(`/api/Chat/GetAllChats`);
  },

  fetchAllUnreadChats: () => {
    return Axios.get(`/api/Chat/GetAllUnreadChats`);
  },

  fetchChatById: (chatId) => {
    return Axios.get(`/api/Chat/GetChatById/${chatId}`);
  },
  createChat: (data) => {
    return Axios({
      url: `/api/Chat/CreateChat`,
      method: "POST",
      data: data,
    });
  },

  //Chat Messages
  fetchAllChatMessages: (chatId) => {
    return Axios.get(`/api/ChatMessage/GetAllChatMessages/${chatId}`);
  },
  createChatMessage: (data) => {
    return Axios({
      url: `/api/ChatMessage/CreateChatMessage`,
      method: "POST",
      data: data,
    });
  },
  readAllMessagesByChat: (chatId) => {
    return Axios({
      url: `/api/ChatMessage/ReadAllMessagesByChat/${chatId}`,
      method: "PUT",
    });
  },
};
