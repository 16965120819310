import React from "react";
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemText,
  Typography,
  ListItemButton,
} from "@mui/material";
import bgImg from "../../../../src/assets/img/aboutPage.jpg";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import { useTranslation } from "react-i18next";

export default function StrategyComponent() {
  const { t } = useTranslation();
  const app_language = localStorage.getItem("lang-er");
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
        }}
      />
      <Grid container sx={{ margin: { xs: 0, md: 3 } }}>
        <Grid item md={1} mb={1}></Grid>
        <Grid item xs={12} md={7} mb={4} sx={{ padding: { xs: 3, md: 0 } }}>
          <Typography
            variant="h2"
            mb={3}
            sx={{ width: { xs: "100%", md: "80%" }, mt: { xs: 3, md: 0 } }}
          >
            {t("homepage.strategy")}
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }}
          >
            {t("association.firstParagraph_Text")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }}
            mb={3}
          >
            {t("association.secondParagraph_Text")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          mb={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 3, md: 0 },
          }}
        >
          <Divider component="li" />
          <List
            sx={{
              py: 0,
              width: "100%",
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider",
              backgroundColor: "background.paper",
            }}
          >
            <ListItemButton>
              <ListItemText
                primary={t("about_us.strategy")}
                sx={{ padding: "7px" }}
              />
              {app_language === "sr" ? (
                <a
                  href={require("../../../../src/assets/doc/strategy_MNE.pdf")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CloudDownloadRoundedIcon />
                </a>
              ) : (
                <a
                  href={require("../../../../src/assets/doc/strategy_ENG.pdf")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <CloudDownloadRoundedIcon />
                </a>
              )}
            </ListItemButton>
            <Divider component="li" />
            <ListItemButton>
              <ListItemText
                primary={t("about_us.regulation")}
                sx={{ padding: "7px" }}
              />
              <CloudDownloadRoundedIcon />
            </ListItemButton>
            <Divider variant="inset" component="li" />
            <ListItemButton>
              <ListItemText
                primary={t("about_us.actionPlan")}
                sx={{ padding: "7px" }}
              />
              <CloudDownloadRoundedIcon />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={1} mb={1}></Grid>
      </Grid>
    </>
  );
}
