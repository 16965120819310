import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PeopleIcon from "@mui/icons-material/People";

import { Link, useParams } from "react-router-dom";
import {
  notifyShowErrorMessage,
  notifyShowSuccessMessage,
  rerender,
} from "store/reducers/app";
import { dispatch } from "store";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import moment from "moment";
import { EventsRepository } from "./EventsRepository";
import Moment from "react-moment";
import { AuthService } from "auth/AuthService";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";

export default function PendingEventDetails({ open, setOpen, eventId }) {
  const { t } = useTranslation();

  const params = useParams();
  const [loadingMembers, setLoadingMembers] = useState(false);

  const [values, setValues] = useState();
  const [loader, setLoader] = useState(true);
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    const loadEvent = (eventId) => {
      setLoadingMembers(true);
      EventsRepository.fetchEventById(eventId)
        .then((res) => {
          setValues(res.data);
          setLoader(false);
          setLoadingMembers(false);
        })
        .catch((err) => {
          setLoader(false);
          dispatch(notifyShowErrorMessage({ message: err?.response?.data }));
        });
    };
    loadEvent(eventId);
  }, []);

  const approveEvent = () => {
    showLoadingBar();
    EventsRepository.approveEvent(eventId)
      .then((res) => {
        setRedirectTo(`/app/events`);
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("success") }));
        hideLoadingBar();
        setOpen(false);
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(
          notifyShowErrorMessage({
            message: t("error"),
          })
        );
      });
  };

  const rejectEvent = () => {
    showLoadingBar();
    EventsRepository.rejectEvent(eventId)
      .then((res) => {
        setRedirectTo(`/app/events`);
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("success") }));
        hideLoadingBar();
        setOpen(false);
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(
          notifyShowErrorMessage({
            message: t("error"),
          })
        );
      });
  };

  const showLoadingBar = () => {
    setLoader(true);
  };

  const hideLoadingBar = () => {
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <Dialog
          open={open}
          maxWidth="md"
          fullWidth={true}
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
          style={{ borderRadius: 0 }}
        >
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography variant="h3" fontStyle={"italic"}>
                {values?.eventName}
              </Typography>
              <IconButton
                color="primary"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item md={12}>
                <img
                  src={`data:image/jpeg;base64,${values?.eventImage}`}
                  alt="Event Image"
                  style={{ width: "100%", height: 350 }}
                />
              </Grid>

              <Grid
                item
                container
                md={12}
                alignItems={"center"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Grid item md={4}>
                  <Stack direction={"row"} spacing={3}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <TodayIcon
                        sx={{
                          color: "#09375c",
                          fontSize: 25,
                          marginRight: 0.5,
                        }}
                      />
                      <Typography fontSize={15}>
                        <Moment format="DD.MM.YYYY">{values?.eventDate}</Moment>
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"}>
                      <AccessTimeIcon
                        sx={{
                          color: "#09375c",
                          fontSize: 25,
                          marginRight: 0.5,
                        }}
                      />
                      <Typography fontSize={15}>
                        <Moment format="HH:mm">{values?.eventDate}</Moment>
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      sx={{
                        color:
                          values?.eventMembers?.length >= values?.numberOfGuest
                            ? "#d42121"
                            : "green",
                        display: values?.isPublic === false ? "flex" : "none",
                      }}
                    >
                      <PeopleIcon
                        sx={{
                          fontSize: 25,
                          marginRight: 0.5,
                        }}
                      />
                      {loadingMembers ? (
                        <MoonLoader size={14} color={"#09375c"} />
                      ) : (
                        <Typography fontSize={15}>
                          {values?.eventMembers?.length}
                        </Typography>
                      )}
                      <Typography fontSize={15}>
                        /{values?.numberOfGuest}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item md={7} display={"flex"} justifyContent={"flex-end"}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <LocationOnIcon
                      sx={{
                        color: "#09375c",
                        fontSize: 25,
                        marginRight: 0.5,
                      }}
                    />
                    <Typography fontSize={15}>
                      {values?.eventLocation}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>

              <Grid item md={12} marginTop={1}>
                <Typography fontSize={14} textAlign={"justify"}>
                  {values?.description}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: 3,
            }}
          >
            <Button
              onClick={rejectEvent}
              variant="contained"
              color="error"
              sx={{ borderRadius: "30px" }}
            >
              {t("reject")}
            </Button>
            <Button
              onClick={approveEvent}
              variant="contained"
              color="success"
              sx={{ borderRadius: "30px" }}
            >
              {t("approve")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* <Grid container marginBottom={3} alignItems={"center"}>
        <Grid item>
          <Link to={`/app/events`} style={{ display: "inline" }}>
            <Avatar sx={{ bgcolor: "white" }}>
              <ArrowBackIosNewIcon sx={{ color: "grey" }} />
            </Avatar>
          </Link>
        </Grid>
        <Grid item md={11}>
          <Typography variant="h3" fontStyle={"italic"}>
            {values?.eventName}
          </Typography>
        </Grid>
      </Grid> */}
    </>
  );
}
