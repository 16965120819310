import Axios from "axios";

export const StudyProgramRepository = {
  fetchStudyProgramsBySearchParameters: (payload) => {
    return Axios({
      url: `/api/StudyProgram/GetStudyProgramsBySearchParameters`,
      method: "GET",
      params: {
        NameME: payload.nameME,
        NameEN: payload.nameEN,
        OrganizationalUnitId: payload.organizationalUnitId,
        StudyLevelId: payload.studyLevelId,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchAllStudyProgramsByParameters: (organizationalUnitId, studyLevelId) => {
    return Axios.get(
      `/api/StudyProgram/GetAllStudyProgramsByParameters/${organizationalUnitId}/${studyLevelId}`
    );
  },

  fetchAllStudyPrograms: () => {
    return Axios.get(`/api/StudyProgram/GetAllStudyPrograms`);
  },

  fetchStudyProgramByID: (studyProgramId) => {
    return Axios.get(`/api/StudyProgram/GetStudyProgramById/${studyProgramId}`);
  },

  createStudyProgram: (data) => {
    return Axios({
      url: `/api/StudyProgram/CreateStudyProgram`,
      method: "POST",
      data: data,
    });
  },

  updateStudyProgram: (data, studyProgramId) => {
    return Axios({
      url: `/api/StudyProgram/UpdateStudyProgram/${studyProgramId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteStudyProgram: (studyProgramId) => {
    return Axios.delete(
      `/api/StudyProgram/DeleteStudyProgram/${studyProgramId}`
    );
  },
};
