import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AuthService } from "auth/AuthService";
import { UserRepository } from "pages/user/UserRepository";
import { Controller, useForm } from "react-hook-form";
import { OrganizationalUnitRepository } from "pages/administration/organizationalUnit/OrganizationalUnitRepository";
import { useDispatch } from "react-redux";
import LoadingSpinner from "./LoadingSpinner";
import { notifyShowSuccessMessage } from "store/reducers/app";
import { AuthRepository } from "auth/AuthRepository";
import { yupResolver } from "@hookform/resolvers/yup";

const AddOrgUnitToUser = ({ open, handleOpen }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userID = AuthService.getDecodedUserJWT()?.ID;
  const app_language = localStorage.getItem("lang-er");

  const [organizationalUnits, setOrganizationalUnits] = useState();

  useEffect(() => {
    loadOrganizationalUnits();
  }, []);

  const loadOrganizationalUnits = () => {
    OrganizationalUnitRepository.fetchAllOrganizationalUnits()
      .then((res) => {
        setOrganizationalUnits(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log("err");
        }
      });
  };

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const Validation = () => {
    return yup.object().shape({
      //autocomplete validation
      organizationalUnit: yup
        .object()
        .transform((value) => (value === null ? {} : value))
        .test(
          "is-orgUnit-selected",
          t("organizationalUnit.org_unit") + t("required_field"),
          (value) => {
            return value && Object.keys(value).length > 0;
          }
        ),
    });
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(Validation()),
    defaultValues: {
      organizationalUnit: null,
    },
  });

  const updateOrgUnit = (values) => {
    showLoadingBar();
    values.organizationalUnitId = values.organizationalUnit.organizationalUnitId;

    UserRepository.updateOrgUnit(values, userID)
      .then((res) => {
        dispatch(
          notifyShowSuccessMessage({
            message: t("users.update_success"),
          })
        );
        hideLoadingBar();
        AuthRepository.logout();
      })
      .catch((err) => {
        hideLoadingBar();
      });
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      PaperProps={{
        style: {
          overflowY: "unset",
          backgroundColor: "#f5f5f5",
        },
      }}
      style={{ borderRadius: 0 }}
    >
      {loading && <LoadingSpinner />}
      <DialogTitle style={{ backgroundColor: "#2196f3", color: "#fff" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5" color="#f3f3f3">
            {t("please_add_org_unit")}
          </Typography>
        </Stack>
      </DialogTitle>
      <form onSubmit={handleSubmit(updateOrgUnit)}>
        <DialogContent dividers sx={{ overflow: "hidden" }}>
          <Controller
            name="organizationalUnit"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                fullWidth
                options={organizationalUnits}
                disabled={!organizationalUnits}
                getOptionLabel={(option) => {
                  return app_language === "sr"
                    ? option?.nameME
                    : app_language === "sr_kir"
                    ? option?.nameMECyrillic
                    : option?.nameEN;
                }}
                onChange={(event, item) => {
                  onChange(item);
                }}
                value={value}
                getOptionSelected={(option, value) =>
                  value === undefined ||
                  value === "" ||
                  option.organizationalUnitId === value.organizationalUnitId
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("organizationalUnit.org_unit")}
                    error={errors?.organizationalUnit}
                    helperText={errors?.organizationalUnit?.message}
                    sx={{
                      ".MuiOutlinedInput-root": {
                        padding: "4px",
                      },
                    }}
                  />
                )}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="space-between" marginRight={2} paddingBottom={1}>
            <Button variant="contained" type="submit">
              {t("save")}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrgUnitToUser;
