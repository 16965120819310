import Axios from "axios";

export const MembersRepository = {
  //fetch
  fetchMembersBySearchParameters: (payload) => {
    return Axios({
      url: `/api/Member/GetMembersBySearchParameters`,
      method: "GET",
      params: {
        NameSurname: payload.nameSurname,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  updateMember: (data, memberId) => {
    return Axios({
      url: `/api/Member/UpdateMember/${memberId}`,
      method: "PUT",
      data: data,
    });
  },

  fetchMemberById: (memberId) => {
    return Axios.get(`/api/Member/GetMemberById/${memberId}`);
  },
};
