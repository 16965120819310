import jwt_decode from "jwt-decode";
var _ = require("lodash");

export const AuthService = {
  setToken: function (tokenData) {
    if (tokenData) {
      window.localStorage.setItem(
        "accessToken",
        JSON.stringify(tokenData.accessToken)
      );
      // const refreshToken = JSON.stringify(tokenData.refreshToken);

      // //get and decode access token
      // const token = JSON.parse(window.localStorage.getItem("accessToken"));
      // let decodedToken = jwt_decode(token);

      // //set refresh token to cookie
      // document.cookie = `refreshToken=${refreshToken}; expires=${new Date(
      //   decodedToken.exp * 10000
      // )}; secure=true; path=/`;
    }
  },
  getToken: function () {
    var currentToken = window.localStorage.getItem("accessToken");
    if (currentToken) {
      return JSON.parse(currentToken);
    }
    return null;
  },
  getDecodedUserJWT: function () {
    let currentUser = window.localStorage.getItem("accessToken");
    if (currentUser) {
      const accessToken = JSON.parse(currentUser);
      const decodedToken = jwt_decode(accessToken);

      return decodedToken;
    }
    return null;
  },
  // hasAnyRole: (roles) => {
  //   var currentUser = AuthService.getDecodedUserJWT();
  //   if (!currentUser?.Role) {
  //     localStorage.removeItem("accessToken");
  //     window.location.href = "/app/login";
  //   } else if (roles.some((item) => currentUser?.Role.includes(item))) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // },
  hasAnyRole: (roles) => {
    var currentUser = AuthService.getDecodedUserJWT();
    if (roles.some((item) => currentUser?.Role.includes(item))) {
      return true;
    } else {
      return false;
    }
  },
};
