//react
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

//mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//defaults
import { rerender, notifyShowSuccessMessage, notifyShowErrorMessage } from "store/reducers/app";
import { useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";

//auth
import { AuthService } from "auth/AuthService";
import { AuthRepository } from "auth/AuthRepository";

export default function EnterNewPasswordModal() {
  const { t } = useTranslation();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const userToken = AuthService.getDecodedUserJWT();
  const [redirectTo, setRedirectTo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm({
    // resolver: yupResolver(),
  });

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const handleShowOldPassword = () => {
    setShowOldPassword((prev) => !prev);
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const updatePassword = (values) => {
    showLoadingBar();
    AuthRepository.changeUserPassword(values, userToken?.ID)
      .then((res) => {
        setRedirectTo(`/app/my-profile`);
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("success") }));
        hideLoadingBar();
        AuthRepository.logout();
      })
      .catch((err) => {
        setErrorMsg(Object.values(err?.response?.data));
        dispatch(notifyShowErrorMessage({ message: t("error") }));
        hideLoadingBar();
      });
  };

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} />}
      <Dialog
        open={true}
        maxWidth="xs"
        // minHeight="sm"
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "unset",
          },
        }}
        style={{ borderRadius: 0 }}
      >
        {loading ? <LinearProgress color="primary" /> : ""}
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{t("users.change_password")}</Typography>
            <Link to="/app/my-profile" style={{ textDecoration: "none" }}>
              <IconButton color="primary">
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Link>
          </Stack>
        </DialogTitle>
        <form onSubmit={handleSubmit(updatePassword)}>
          <DialogContent dividers>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <TextField
                  label={t("users.old_password")}
                  variant="outlined"
                  error={errors?.oldPassword}
                  helperText={errors?.oldPassword?.message}
                  name="oldPassword"
                  type={showOldPassword ? "text" : "password"}
                  {...register("oldPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleShowOldPassword}
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={t("users.new_password")}
                  variant="outlined"
                  error={errors?.newPassword}
                  helperText={errors?.newPassword?.message}
                  name="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  {...register("newPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleShowNewPassword}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label={t("users.confirm_new_password")}
                  variant="outlined"
                  error={errors?.confirmNewPassword}
                  helperText={errors?.confirmNewPassword?.message}
                  name="confirmNewPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("confirmNewPassword")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleShowConfirmPassword}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Grid>
            <Grid item mt={2}>
              {errorMsg &&
                errorMsg?.map((item, idx) => {
                  return (
                    <Box key={idx} sx={{ marginBottom: 1 }}>
                      <Typography color="red" fontSize={"12px"}>
                        &bull; {item}
                      </Typography>
                    </Box>
                  );
                })}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Button variant="outlined" type="submit" disabled={loading}>
                {t("save")}
              </Button>
              <Link to={"/app/my-profile"} style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="secondary" disabled={loading}>
                  {t("cancel")}
                </Button>
              </Link>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
