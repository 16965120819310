import {
  Box,
  Divider,
  Grid,
  List,
  ListItemText,
  Typography,
  ListItemButton,
} from "@mui/material";
import bgImg from "../../../../src/assets/img/aboutPage.jpg";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import { useTranslation } from "react-i18next";

const AboutComponent = () => {
  const { t } = useTranslation();
  const app_language = localStorage.getItem("lang-er");
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
        }}
      />
      <Grid container sx={{ margin: { xs: 0, md: 3 } }}>
        <Grid item md={1} mb={1}></Grid>
        <Grid item xs={12} md={7} mb={4} sx={{ padding: { xs: 3, md: 0 } }}>
          <Typography
            variant="h2"
            mb={3}
            sx={{ width: { xs: "100%", md: "80%" }, mt: { xs: 3, md: 0 } }}
          >
            {t("association.title")}
          </Typography>
          <Typography
            variant="body1"
            mb={2}
            sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }}
          >
            {t("association.firstParagraph_Text")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }}
            mb={3}
          >
            {t("association.secondParagraph_Text")}
          </Typography>
        </Grid>
        <Grid item md={1} mb={1}></Grid>
      </Grid>
    </>
  );
};

export default AboutComponent;
