import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { clearNotifyMessage } from "store/reducers/app";
import MuiAlert from "@mui/material/Alert";
import React, { useEffect } from "react";
import { AuthService } from "auth/AuthService";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MinimalLayout() {
  const isAuth = AuthService.getDecodedUserJWT();
  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     window.location.href = "/app/login";
  //   }

  //   if (!isAuth) {
  //     window.location.href = "/app/login";
  //   }
  //   if (isAuth && location.pathname === "/app") {
  //     navigate("/app/dashboard");
  //   }
  // }, [isAuth, location.pathname, navigate]);

  const { openNotification, message, variant } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (openNotification) {
      dispatch(clearNotifyMessage());
    }
  };
  return (
    <>
      {openNotification && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={variant}>
            {message}
          </Alert>
        </Snackbar>
      )}
      <Outlet />
    </>
  );
}
