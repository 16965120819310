import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  // IconButton,
  Paper,
  Popper,
  Stack,
  // Tab,
  // Tabs,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import ProfileTab from "./ProfileTab";
// import SettingTab from "./SettingTab";
import { AuthService } from "auth/AuthService";
import { AuthRepository } from "auth/AuthRepository";

// assets
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import KeyIcon from "@mui/icons-material/Key";
import ChangeLanguageComponent from "components/ChangeLanguageComponent";
import ProfileSettings from "./ProfileSettings";
import NotificationsComponent from "components/NotificationsComponent";
import ChatNotifications from "components/Chat/ChatNotifications";
import { UserAccountsRepository } from "pages/userAccount/UserAccountsRepository";
import { useSelector } from "react-redux";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();

  const [userInfo, setUserInfo] = useState();
  const [openProfileSettings, setOpenProfileSettings] = useState(false);

  const data = AuthService.getDecodedUserJWT();
  const dataObjVals = Object.values(data);

  const { triggerRerender } = useSelector((state) => state.app);

  const handleLogout = async () => {
    AuthRepository.logout().then(
      (res) => {},
      (err) => {}
    );
  };

  useEffect(() => {
    getUserInfo();
  }, [triggerRerender]);

  const getUserInfo = () => {
    UserAccountsRepository.getUserSelfInfo()
      .then((res) => {
        setUserInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [openNotifications, setOpenNotifications] = useState();
  const handleToggleNotifications = () => {
    setOpenNotifications((prevOpen) => !prevOpen);
  };
  const handleCloseNotifications = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenNotifications(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = "grey.300";

  return (
    <>
      {openProfileSettings && (
        <ProfileSettings isOpen={openProfileSettings} handleIsOpen={setOpenProfileSettings} />
      )}
      <Box
        sx={{
          flexShrink: 0,
          ml: 0.75,
          display: "flex",
          alignItems: "center",
          bgcolor: "inherit",
        }}
      >
        <Stack direction={"row"}>
          <ChangeLanguageComponent background={"#138AD9"} color={"#09375c"} />
          {/* <NotificationsComponent /> */}
          <ChatNotifications />
        </Stack>
        <ButtonBase
          sx={{
            p: 0.25,
            borderRadius: 1,
            // "&:hover": { bgcolor: "#0189CF" },
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
            <Avatar
              alt="profile user"
              src={
                userInfo?.member?.userIcon
                  ? `data:image/jpeg;base64,${userInfo.member.userIcon}`
                  : ""
              }
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#09375c",
                border: "1px solid #09375c",
              }}
            />
          </Stack>
        </ButtonBase>
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, 9],
                },
              },
            ],
          }}
        >
          {({ TransitionProps }) => (
            <Transitions type="fade" in={open} {...TransitionProps}>
              {open && (
                <Paper
                  sx={{
                    boxShadow: theme.customShadows.z1,
                    width: "fit-content",
                    minWidth: 240,
                    maxWidth: "fit-content",
                    [theme.breakpoints.down("md")]: {
                      maxWidth: "fit-content",
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <MainCard elevation={0} border={false} content={false}>
                      <CardContent sx={{ px: 2, pt: 3 }}>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            <Avatar
                              alt="profile user"
                              src={
                                userInfo?.member?.userIcon
                                  ? `data:image/jpeg;base64,${userInfo.member.userIcon}`
                                  : ""
                              }
                              sx={{
                                width: 32,
                                height: 32,
                                border: "1px solid #09375c",
                                backgroundColor: "#09375c",
                              }}
                            >
                              {userInfo?.firstName[0]}
                            </Avatar>
                            <Stack>
                              <Typography variant="h6">{`${userInfo?.firstName} ${userInfo?.lastName}`}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {userInfo?.member?.email}
                              </Typography>
                            </Stack>
                          </Stack>
                          {/* <Stack width={"10%"}>
                            <Button
                              disableRipple
                              sx={{
                                padding: 0,
                                margin: 0,
                                textTransform: "none",
                                minWidth: "100%",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              onClick={() => {
                                setOpenProfileSettings(true);
                              }}
                            >
                              <KeyIcon />
                            </Button>
                          </Stack> */}
                        </Grid>
                      </CardContent>
                      {open && (
                        <>
                          <Box sx={{ borderBottom: 2, borderColor: "#0189CF" }}></Box>
                          <TabPanel value={value} index={0} dir={theme.direction}>
                            <ProfileTab handleLogout={handleLogout} handleToggle={handleToggle} />
                          </TabPanel>
                        </>
                      )}
                    </MainCard>
                  </ClickAwayListener>
                </Paper>
              )}
            </Transitions>
          )}
        </Popper>
      </Box>
    </>
  );
};

export default Profile;
