import { useEffect, useState } from "react";
import { Link, Navigate, Outlet, useLocation, useParams } from "react-router-dom";

// material-ui
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SendIcon from "@mui/icons-material/Send";

import { useDispatch, useSelector } from "react-redux";
import { rerender, notifyShowSuccessMessage, notifyShowErrorMessage } from "store/reducers/app";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import blank from "assets/img/blank_avatar.jpg";
import img3 from "assets/img/man1.jpg";
import img4 from "assets/img/man2.jpg";
import moment from "moment";
import { ChatRepository } from "./ChatRepository";
import { AuthService } from "auth/AuthService";

export default function ChatComponent() {
  const { t } = useTranslation();

  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const unreadMessageChat = location?.state?.unreadMessageChat;

  const [messageInput, setMessageInput] = useState("");

  const { triggerRerender } = useSelector((state) => state.app);

  ///api chats
  const [allChats, setAllChats] = useState();
  const [chatMessages, setChatMessages] = useState();

  const [selectedChat, setSelectedChat] = useState();

  const userToken = AuthService.getDecodedUserJWT();

  useEffect(() => {
    loadAllChats();
  }, []);

  const readAllMessagesByChat = (chatId) => {
    ChatRepository.readAllMessagesByChat(chatId).then().catch();
  };

  const loadAllChats = () => {
    setLoading(true);
    ChatRepository.fetchAllChats()
      .then((res) => {
        setAllChats(res.data);
        if (unreadMessageChat) {
          handleChatSelect(unreadMessageChat, unreadMessageChat?.chatId);
          readAllMessagesByChat(unreadMessageChat?.chatId);
          dispatch(rerender());
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadAllChatMessages = (chatId) => {
    ChatRepository.fetchAllChatMessages(chatId)
      .then((res) => {
        setChatMessages(res.data);
        console.log("chatMsgs", res.data);
      })
      .catch((err) => {});
  };

  const handleChatSelect = (chat, chatId) => {
    setSelectedChat(chat);
    loadAllChatMessages(chatId);
  };

  const handleMessageInputChange = (event, chatId) => {
    setMessageInput(event.target.value);

    if (event.key === "Enter") {
      sendMessageToChat(chatId);
    }
  };

  const sendMessageToChat = (chatId) => {
    const trimmedMessageInput = messageInput.trim();
    if (trimmedMessageInput !== "") {
      const data = {
        textMessage: messageInput,
        userAccountId: userToken?.ID,
        chatId: chatId,
      };

      ChatRepository.createChatMessage(data)
        .then((res) => {
          loadAllChatMessages(chatId);
        })
        .catch((err) => {});

      setMessageInput("");
    }
  };

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} />}
      <Dialog
        open={true}
        maxWidth="md"
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "unset",
            backgroundColor: "#f5f5f5",
          },
        }}
        style={{ borderRadius: 0 }}
      >
        {loading && <LoadingSpinner />}
        <DialogTitle style={{ backgroundColor: "#2196f3", color: "#fff" }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5" color="#f3f3f3">
              {t("messages")}
            </Typography>
            <Link to="/app/news-feed" style={{ textDecoration: "none" }}>
              <IconButton sx={{ color: "#f3f3f3" }}>
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Link>
          </Stack>
        </DialogTitle>
        <DialogContent dividers sx={{ overflow: "hidden" }}>
          <Grid container spacing={2}>
            {/* Left side - List of chats */}
            <Grid item md={4}>
              <Paper
                sx={{
                  height: 550,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: 3,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "##4594f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#4594f1",
                  },
                }}
              >
                <List>
                  {allChats?.map((chat) => (
                    <ListItem
                      key={chat.chatId}
                      button
                      onClick={() => handleChatSelect(chat, chat.chatId)}
                      sx={{
                        backgroundColor: selectedChat?.chatId === chat?.chatId ? "lightgray" : "",
                      }}
                    >
                      <ListItemIcon sx={{ marginRight: 1 }}>
                      {
                          userToken?.ID === chat.userFrom.id
                            ? 
                            <Avatar src={chat?.userTo?.member?.userIcon ? `data:image/jpeg;base64,${chat?.userTo?.member?.userIcon}`
                            : blank} />
                            : 
                            <Avatar src={chat?.userFrom?.member?.userIcon ? `data:image/jpeg;base64,${chat?.userFrom?.member?.userIcon}` : blank} />
                        }
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          userToken?.ID === chat.userFrom.id
                            ? `${chat?.userTo?.firstName} ${chat?.userTo?.lastName}`
                            : `${chat?.userFrom?.firstName} ${chat?.userFrom?.lastName}`
                        }
                        // secondary={chat.timestamp}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
            {/* Right side - Selected chat */}
            <Grid item md={8}>
              <Grid container>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: 500,
                    overflowY: "auto",
                    padding: 1,
                    "&::-webkit-scrollbar": {
                      width: 3,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "##4594f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#4594f1",
                    },
                  }}
                >
                  {selectedChat && (
                    <>
                      {chatMessages?.map((item) => {
                        return userToken?.ID === item?.userAccount.id ? (
                          <Paper
                            key={item?.chatMessageId}
                            sx={{
                              padding: 1.5,
                              marginLeft: "auto",
                              marginBottom: 1,
                              backgroundColor: "#09375c",
                              color: "#fff",
                              // marginTop: "auto",
                            }}
                          >
                            <Typography sx={{ wordBreak: "break-all" }}>
                              {item?.textMessage}
                            </Typography>
                          </Paper>
                        ) : (
                          <Stack
                            key={item?.chatMessageId}
                            direction={"row"}
                            spacing={1}
                            width={"49%"}
                            alignItems={"center"}
                            marginBottom={1}
                            marginTop={"auto"}
                          >
                            <Avatar
                              src={img3}
                              sx={{ width: 32, height: 32, alignSelf: "flex-end" }}
                            />
                            <Paper sx={{ padding: 1.5 }}>
                              <Typography sx={{ wordBreak: "break-all" }}>
                                {item?.textMessage}
                              </Typography>
                            </Paper>
                          </Stack>
                        );
                      })}
                    </>
                  )}
                  <Box sx={{ marginY: 1 }} />
                </Grid>
                <Grid item md={12}>
                  {selectedChat && (
                    <TextField
                      label="Message"
                      variant="outlined"
                      value={messageInput}
                      onChange={(event) => {
                        handleMessageInputChange(event, selectedChat?.chatId);
                      }}
                      onKeyDown={(event) => {
                        handleMessageInputChange(event, selectedChat?.chatId);
                      }}
                      fullWidth
                      // multiline
                      sx={{
                        marginTop: "auto",
                        alignSelf: "flex-end",
                        wordBreak: "break-all",
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              sendMessageToChat(selectedChat?.chatId);
                            }}
                            edge="end"
                          >
                            <SendIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Outlet />
      </Dialog>
    </>
  );
}
