import Axios from "axios";

export const UserAccountsRepository = {
  fetchUsersBySearchParameters: (payload) => {
    return Axios({
      url: `/api/User/GetUsersBySearchParameters`,
      method: "GET",
      params: {
        FirstName: payload.firstName,
        LastName: payload.lastName,
        UserName: payload.userName,
        RoleName: payload.roleName,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  getUserSelfInfo: () => {
    return Axios.get(`/api/User/GetSelfUserInfo`);
  },

  fetchAllUserAccounts: () => {
    return Axios.get(`/api/UserAccount/GetAllUserAccounts`);
  },

  fetchUserAccountByID: (userAccountId) => {
    return Axios.get(`/api/UserAccount/GetUserAccountById/${userAccountId}`);
  },

  createUserAccount: (data) => {
    return Axios({
      url: `/api/UserAccount/CreateUserAccount`,
      method: "POST",
      data: data,
    });
  },

  updateUserAccount: (data, userAccountId) => {
    return Axios({
      url: `/api/UserAccount/UpdateUserAccount/${userAccountId}`,
      method: "PUT",
      data: data,
    });
  },

  getAllRoleTypes: () => {
    return Axios.get(`/api/RoleType/GetAllRoleTypes`);
  },

  changeUserPassword: (data, userAccountId) => {
    return Axios({
      url: `/api/UserAccount/ChangeAdminToUserPassword/${userAccountId}`,
      method: "PUT",
      data: data,
    });
  },
};
