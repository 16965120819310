import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";
import zIndex from "@mui/material/styles/zIndex";
import logo from "../../../assets/img/logo2.png";
import logoEU from "../../../assets/img/eu_logo.png";
import { Link } from "react-router-dom";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // const iconBackColor = "grey.100";
  // const iconBackColorOpen = "grey.200";

  // common header
  const mainHeader = (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "55px",
          backgroundColor: "#09375c",
          zIndex: 999,
          paddingX: 3,
        }}
      >
        <Link to={`/home`} style={{ lineHeight: 0, textDecoration: "none" }}>
          <Box component="img" sx={{ height: 35 }} src={logo} />
        </Link>
        <Box component="img" sx={{ height: 35 }} src={logoEU} />
      </Box>
      <Toolbar
        sx={{
          backgroundColor: "white",
          paddingY: 0.8,
          width: "100%",
          position: "fixed",
          boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
          left: 0,
          top: 50,
        }}
      >
        <HeaderContent />
      </Toolbar>
    </>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      // boxShadow: theme.customShadows.z1
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default Header;
