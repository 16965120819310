import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthRegister = Loadable(lazy(() => import("pages/authentication/Register")));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/app",
  element: <MinimalLayout />,
  children: [
    {
      path: "/app/login",
      element: <AuthLogin />,
    },
    {
      path: "/app/register",
      element: <AuthRegister />,
    },
  ],
};

export default LoginRoutes;
