import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Collapse,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// project import
import NavItem from "./NavItem";
import { forwardRef, useState } from "react";
import { activeItem } from "store/reducers/menu";
import { dispatch } from "store";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AuthService } from "auth/AuthService";
import { useTranslation } from "react-i18next";

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen, componentDrawerOpen, openItem } = menu;

  const textColor = "common.black";
  const iconSelectedColor = "common.white";

  // const MenuItem = ({ item }) => {
  //   const Component = item.type == "collapse" ? MultiLevel : SingleLevel;
  //   if (AuthService.hasAnyPermission(item.hasPermission)) {
  //     return <Component item={item} level={item.type == "children" ? 2 : 1} />;
  //   }
  // };

  const MenuItem = ({ item }) => {
    const Component = item.type == "collapse" ? MultiLevel : SingleLevel;
    if (AuthService.hasAnyRole(item.hasAccess)) {
      return <Component item={item} level={item.type == "children" ? 2 : 1} />;
    }
  };

  const SingleLevel = ({ item, level }) => {
    const Icon = item.icon;
    const itemIcon = item.icon ? (
      <Icon
        style={{
          fontSize: drawerOpen ? "1rem" : "1.25rem",
        }}
      />
    ) : (
      false
    );
    const isSelected =
      document.location.pathname
        .toString()
        .split("/")
        .findIndex((id) => id === item.id) > -1;

    let itemTarget = "_self";
    if (item.target) {
      itemTarget = "_blank";
    }
    let listItemProps = {
      component: forwardRef((props, ref) => (
        <Link ref={ref} {...props} to={item.url} target={itemTarget} />
      )),
    };

    const itemHandler = (id) => {
      dispatch(activeItem({ openItem: [id] }));
    };

    return (
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        onClick={() => itemHandler(item.id)}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            "&.Mui-selected": {
              bgcolor: "#09375c",
              color: iconSelectedColor,
              "&:hover": {
                bgcolor: "#09375c",
              },
            },
          }),
          ...(!drawerOpen && {
            "&:hover": {
              bgcolor: "transparent",
            },
            "&.Mui-selected": {
              "&:hover": {
                bgcolor: "transparent",
              },
              bgcolor: "transparent",
            },
          }),
        }}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isSelected ? iconSelectedColor : "#09375c",
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  bgcolor: "secondary.lighter",
                },
              }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor: "primary.lighter",
                  "&:hover": {
                    bgcolor: "primary.lighter",
                  },
                }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {item.id === "incomingStudentProgrammes" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("incomingStudentProgrammes.partI")} <br />{" "}
                {t("incomingStudentProgrammes.partII")}
              </Typography>
            }
          />
        ) : item.id === "outgoingStudentApplications" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("outgoingStudentApplication.titleI")} <br />{" "}
                {t("outgoingStudentApplication.titleII")}
              </Typography>
            }
          />
        ) : item.id === "outgoingStaffApplications" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("outgoingStaffApplication.titleI")} <br />{" "}
                {t("outgoingStaffApplication.titleII")}
              </Typography>
            }
          />
        ) : item.id === "incomingStudentApplications" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("incomingStudentApplication.titleI")} <br />{" "}
                {t("incomingStudentApplication.titleII")}
              </Typography>
            }
          />
        ) : item.id === "incomingStaffApplications" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("incomingStaffApplication.titleI")} <br />{" "}
                {t("incomingStaffApplication.titleII")}
              </Typography>
            }
          />
        ) : item.id === "outgoingMobilityApplicationCalls" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("outgoingMobilityApplicationCall.titleI")} <br />{" "}
                {t("outgoingMobilityApplicationCall.titleII")}
              </Typography>
            }
          />
        ) : (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {item.title}
              </Typography>
            }
          />
        )}

        {/* {item.id === "incomingStudentProgrammes" ? (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {t("incomingStudentProgrammes.partI")} <br />{" "}
                {t("incomingStudentProgrammes.partII")}
              </Typography>
            }
          />
        ) : (
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? iconSelectedColor : "#001A39",
                }}
              >
                {item.title}
              </Typography>
            }
          />
        ) } */}
      </ListItemButton>
    );
  };

  const MultiLevel = ({ item, level }) => {
    const navigate = useNavigate();

    const Icon = item.icon;
    const itemIcon = item.icon ? (
      <Icon
        style={{ fontSize: drawerOpen ? "1rem" : "1.25rem", color: "#09375c" }}
      />
    ) : (
      false
    );
    const isSelected =
      document.location.pathname
        .toString()
        .split("/")
        .findIndex((id) => id === item.id) > -1;

    const isOpen =
      document.location.pathname
        .toString()
        .split("/")
        .findIndex((id) => id === item.id) > -1;

    const { items: children } = item;
    const [open, setOpen] = useState(isOpen);

    const handleClick = () => {
      setOpen((prev) => !prev);
      if (item.id === "groups") {
        navigate("/app/groups");
      }
    };

    return (
      <>
        <ListItemButton
          onClick={handleClick}
          selected={isSelected}
          sx={{
            zIndex: 1201,
            pl: drawerOpen ? `${level * 28}px` : 1.5,
            py: !drawerOpen && level === 1 ? 1.25 : 1,
            ...(drawerOpen && {
              "&.Mui-selected": {
                bgcolor: "transparent",
                color: "common.white",
              },
            }),
            ...(!drawerOpen && {
              "&:hover": {
                bgcolor: "transparent",
              },
              "&.Mui-selected": {
                "&:hover": {
                  bgcolor: "transparent",
                },
                bgcolor: "transparent",
              },
            }),
          }}
        >
          {itemIcon && (
            <ListItemIcon
              sx={{
                minWidth: 28,
                color: isSelected ? "primary.main" : textColor,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    bgcolor: "secondary.lighter",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  }),
              }}
            >
              {itemIcon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={
              <Typography
                variant="h6"
                sx={{
                  color: isSelected ? textColor : textColor,
                  // "&:hover": {
                  //   color: iconSelectedColor,
                  // },
                  // ????
                }}
              >
                {item.title}
              </Typography>
            }
          />
          {open ? (
            <ExpandLess
              sx={{
                minWidth: 28,
                color: isSelected ? "primary.main" : textColor,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    bgcolor: "secondary.lighter",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  }),
              }}
            />
          ) : (
            <ExpandMore
              sx={{
                minWidth: 28,
                color: isSelected ? "primary.main" : textColor,
                ...(!drawerOpen && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: "center",
                  justifyContent: "center",
                  "&:hover": {
                    bgcolor: "secondary.lighter",
                  },
                }),
                ...(!drawerOpen &&
                  isSelected && {
                    bgcolor: "primary.lighter",
                    "&:hover": {
                      bgcolor: "primary.lighter",
                    },
                  }),
              }}
            />
          )}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item?.children?.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </>
    );
  };

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case "collapse":
        return (
          <>
            <NavItem key={menuItem.id} item={menuItem} level={1} />
            <Collapse in={componentDrawerOpen}>
              {menuItem.children.map((childItem) => {
                return (
                  <NavItem key={childItem.id} item={childItem} level={2} />
                );
              })}
            </Collapse>
          </>
        );
      case "item":
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography
            key={menuItem.id}
            variant="h6"
            color="error"
            align="center"
          >
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {item?.children?.map((item, key) => (
        <MenuItem key={key} item={item} />
      ))}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
