import { useTranslation } from "react-i18next";
import { Box, Button, Menu, MenuItem, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rerender } from "store/reducers/app";
import LanguageIcon from "@mui/icons-material/Language";
import gbFlag from "../assets/img/gb_flag.png";
import meFlag from "../assets/img/me_flag.png";
import { AuthService } from "auth/AuthService";

export default function ChangeLanguageComponent({ background, hoverColor, color }) {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  useEffect(() => {
    const lang = window.localStorage.getItem("lang-er");
    if (lang === null) {
      window.localStorage.setItem("lang-er", "sr");
    } else {
      i18n.changeLanguage(lang);
    }
  }, []);

  const isAuth = AuthService.getDecodedUserJWT();
  const mainLayout = window.location.pathname.includes("app");

  function changeLanguage(langauge) {
    i18n.changeLanguage(langauge);
    window.localStorage.setItem("lang-er", langauge);
    dispatch(rerender());
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const languageArr = [
    {
      id: 1,
      name: "English",
      icon: gbFlag,
      language: "en",
    },
    {
      id: 2,
      name: "Montenegrin",
      icon: meFlag,
      language: "sr",
    },
    {
      id: 3,
      name: "Montenegrin_kir",
      icon: meFlag,
      language: "sr_kir",
    },
  ];

  return (
    <>
      <Button
        sx={{
          border: "none",
          color: !mainLayout ? "#fff" : "#09375c",
          // "&:hover": {
          //   border: "1px solid #138AD9",
          //   backgroundColor: "#138AD9",
          //   color: "#ffffff",
          // },
          minWidth: 32,
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <LanguageIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ marginTop: 0.8 }}
      >
        <Box sx={{ paddingX: 0.5 }}>
          {languageArr.map((item) => {
            return (
              <MenuItem
                key={item.id}
                onClick={() => {
                  handleClose();
                  changeLanguage(item.language);
                }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <Box
                    component="img"
                    alt={item.name}
                    src={item.icon}
                    sx={{ width: 25, height: 15 }}
                  />
                  {item.language === "sr_kir" ? (
                    <span>{`[A-Ш]`}</span>
                  ) : item.language === "sr" ? (
                    <span>{`[A-Š]`}</span>
                  ) : (
                    <span>{`[A-Z]`}</span>
                  )}
                </Stack>
              </MenuItem>
            );
          })}
        </Box>
      </Menu>
    </>
  );
}
