import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkIcon from "@mui/icons-material/Link";

import { notifyShowErrorMessage, notifyShowSuccessMessage, rerender } from "store/reducers/app";
import { dispatch } from "store";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import { JobsRepository } from "./JobsRepository";

export default function PendingJobDetails({ open, setOpen, jobId }) {
  const { t } = useTranslation();

  const [values, setValues] = useState();
  const [loader, setLoader] = useState(true);
  const [redirectTo, setRedirectTo] = useState();

  useEffect(() => {
    const loadJob = (jobId) => {
      JobsRepository.fetchJobById(jobId)
        .then((res) => {
          setValues(res.data);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          dispatch(notifyShowErrorMessage({ message: `${t("error")} !` }));
        });
    };
    loadJob(jobId);
  }, []);

  const approveJob = () => {
    showLoadingBar();
    JobsRepository.approveJob(jobId)
      .then((res) => {
        setRedirectTo(`/app/jobs`);
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("success") }));
        hideLoadingBar();
        setOpen(false);
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(
          notifyShowErrorMessage({
            message: t("error"),
          })
        );
      });
  };

  const rejectJob = () => {
    showLoadingBar();
    JobsRepository.rejectJob(jobId)
      .then((res) => {
        setRedirectTo(`/app/jobs`);
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("success") }));
        hideLoadingBar();
        setOpen(false);
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(
          notifyShowErrorMessage({
            message: t("error"),
          })
        );
      });
  };

  const showLoadingBar = () => {
    setLoader(true);
  };

  const hideLoadingBar = () => {
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <LoadingSpinner />
      ) : (
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth={true}
          PaperProps={{
            style: {
              overflowY: "unset",
            },
          }}
          style={{ borderRadius: 0 }}
        >
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
              <Typography variant="h3" fontStyle={"italic"}>
                {values?.companyName} - {values?.jobTitle}
              </Typography>
              <IconButton
                color="primary"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <Box display="flex" justifyContent={"space-between"} key={values?.jobId}>
              <Box sx={{ flex: "0 0 8%" }}>
                <img
                  src={`data:image/jpeg;base64,${values?.jobLogo}`}
                  style={{ width: 62, height: 62, marginRight: 3 }}
                  alt="Job logo"
                />
              </Box>
              <Box sx={{ flex: "0 0 90%" }}>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                  <Box sx={{ flex: "0 0 80%" }}>
                    <Typography variant="h6">{values?.companyName}</Typography>
                    <Typography variant="subtitle1">{values?.jobTitle}</Typography>
                    <Stack direction={"row"} alignItems={"center"}>
                      <LocationOnIcon sx={{ fontSize: 16 }} />
                      <Typography variant="subtitle2">{values?.jobLocation}</Typography>
                    </Stack>
                  </Box>
                  <Box
                    sx={{
                      flex: "0 0 18%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title={t("jobs.url")}>
                      <a
                        href={values?.jobURL}
                        target="_blank"
                        style={{
                          textDecoration: "none",
                          color: "#09375c",
                          display: "inline",
                          height: 20,
                        }}
                      >
                        <LinkIcon sx={{ fontSize: "30px" }} />
                      </a>
                    </Tooltip>
                  </Box>
                </Box>
                {values?.description && (
                  <>
                    <Divider style={{ margin: "8px 0" }} />
                    <Typography variant="body2" sx={{ textAlign: "justify" }}>
                      {values?.description}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: 3,
            }}
          >
            <Button
              onClick={rejectJob}
              variant="contained"
              color="error"
              sx={{ borderRadius: "30px" }}
            >
              {t("reject")}
            </Button>
            <Button
              onClick={approveJob}
              variant="contained"
              color="success"
              sx={{ borderRadius: "30px" }}
            >
              {t("approve")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
