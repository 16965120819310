import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

//mui
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import GroupsIcon from "@mui/icons-material/Groups";
import { clearNotifyMessage } from "store/reducers/app";

//logo
import logo from "../../assets/img/logo2.png";
import logoEU from "../../assets/img/eu_logo.png";
import euLogoMobile from "../../assets/img/euLogoMobile.png";

import Profile from "layout/MainLayout/Header/HeaderContent/Profile";
import ChangeLanguageComponent from "components/ChangeLanguageComponent";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "auth/AuthService";
import RegistrationModal from "pages/public-pages/RegistrationModal/RegistrationModal";
import { Button, Menu, MenuItem, Popover } from "@mui/material";
import AuthFooter from "components/cards/AuthFooter";
import AboutComponent from "pages/public-pages/About/AboutComponent";
// import i18n from "i18n/config";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PublicLayout(props) {
  const { t } = useTranslation();

  const { window } = props;
  const [currentMenuItem, setCurrentMenuItem] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const { openNotification, message, variant } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();

  const [openRegistrationModal, setOpenRegistrationModal] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    if (openNotification) {
      dispatch(clearNotifyMessage());
    }
  };

  const drawerWidth = 240;
  const navItems = [
    { id: 1, name: t("user_login"), href: "/app/login", icon: <LoginIcon /> },
    {
      id: 2,
      name: t("join_us"),
      href: "/app/register",
      // icon: <RegisterIcon />,
    },
  ];

  const loginMobileItems = [
    { id: 1, name: t("my_profile"), href: "/app/news-feed" },
  ];

  //auth
  const isAuthenticated = AuthService.getToken();

  const menuItems = [
    { id: 1, name: t("homepage.home"), href: "/home", multiple: false },
    {
      id: 2,
      name: t("menu.news_feed"),
      href: "/news",
      multiple: false,
    },
    {
      id: 3,
      name: t("homepage.about"),
      href: "/about",
      multiple: true,
      children: [
        {
          id: 1,
          name: t("homepage.aboutUs"),
          href: "/about",
        },
        {
          id: 2,
          name: t("homepage.rectorate"),
          href: "/about/rectorate",
        },
        {
          id: 3,
          name: t("homepage.strategy"),
          href: "/about/strategy",
        },
      ],
    },
    {
      id: 4,
      name: t("homepage.association"),
      href: "/association",
      multiple: false,
    },
    { id: 5, name: t("homepage.events"), href: "/events", multiple: false },
    {
      id: 6,
      name: t("homepage.careers"),
      href: "/careers",
      multiple: false,
    },
    {
      id: 7,
      name: t("homepage.recognition"),
      href: "/recognition",
      multiple: false,
    },
  ];

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
      setCurrentMenuItem("/home");
    } else if (location.pathname === "/register") {
      setCurrentMenuItem("");
    } else if (location.pathname === "/home") {
      setCurrentMenuItem("/home");
    }
    setCurrentMenuItem(location.pathname);
  }, [location.pathname, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //mobile-view
  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Box
        component="img"
        sx={{
          height: 55,
          width: "100%",
          padding: 1,
        }}
        src={euLogoMobile}
      />

      <Divider />

      <List>
        {isAuthenticated
          ? loginMobileItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton>
                  <Link
                    to={item.href}
                    style={{ textDecoration: "none", color: "#001833" }}
                  >
                    <ListItemText primary={item.name} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))
          : navItems.map((item) => (
              <ListItem key={item.id} disablePadding>
                <ListItemButton>
                  <Link
                    to={item.href}
                    style={{ textDecoration: "none", color: "#001833" }}
                  >
                    <ListItemText primary={item.name} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
        {menuItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton>
              <Link
                to={item.href}
                style={{ textDecoration: "none", color: "#001833" }}
              >
                <ListItemText primary={item.name} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleAboutCommunityClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAboutCommunityClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {openRegistrationModal && (
        <RegistrationModal
          setOpenRegistrationModal={setOpenRegistrationModal}
          openRegistrationModal={openRegistrationModal}
        />
      )}
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          justifyContent: "right",
          backgroundColor: "#082f4f",
          paddingTop: 9,
          paddingBottom: 0.5,
          paddingRight: 3,
        }}
      >
        <Box sx={{ display: { xs: "none", sm: "flex", alignItems: "center" } }}>
          {isAuthenticated ? (
            <Profile />
          ) : (
            <Stack
              gap={1}
              alignItems={"center"}
              direction={"row"}
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#fff",
                  border: "1px solid #fff",
                  borderRadius: 0,
                  height: 35,
                  "&:hover": {
                    color: "#fff",
                  },
                  textTransform: "initial",
                }}
                startIcon={<GroupsIcon />}
                onClick={() => {
                  setOpenRegistrationModal(true);
                }}
              >
                {t("join_us")}
              </Button>
              <Link
                to={`/app/login`}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  height: 35,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    color: "#fff",
                    border: "1px solid #fff",
                    borderRadius: 0,
                    height: 35,
                    "&:hover": {
                      color: "#fff",
                    },
                    textTransform: "initial",
                  }}
                  startIcon={<PersonIcon />}
                >
                  {t("user_login")}
                </Button>
              </Link>
              <ChangeLanguageComponent background={"#FFFF"} color={"#ffffff"} />
            </Stack>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "100vh",
          // paddingTop: { xs: 5 },
        }}
        className="nav"
      >
        {/* Mobile menu  */}
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

        {/* PC Menu view  */}
        <AppBar
          component="nav"
          className="smallNavbar"
          sx={{
            paddingX: 2,
            paddingY: 1,
            paddingRight: 1,
            backgroundColor: "#09375c",
            boxShadow: "none",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              ".css-1y2fluj-MuiToolbar-root": {
                paddingLeft: 0,
                paddingRight: 0,
              },
            }}
          >
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <a
              href="https://www.ucg.ac.me"
              target="_blank"
              rel="norefereer"
              className="ucg-logo-public"
            >
              <Box
                className="erasmusLogo"
                component="img"
                sx={{
                  display: { xs: "none", md: "block" },
                  // height: 125,
                  width: 220,
                  maxHeight: { xs: 120, md: 167 },
                  maxWidth: { xs: 140, md: 250 },
                  marginRight: { xs: 3, md: 0 },
                }}
                src={logo}
              />
            </a>
            <Stack
              sx={{ display: { xs: "none", sm: "flex" } }}
              direction={"row"}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              {menuItems.map((item) => {
                if (item.multiple) {
                  return (
                    <div>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onMouseEnter={handleAboutCommunityClick}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            textTransform: "uppercase",
                            fontWeight: 500,
                            fontSize: { xs: 12, md: 14 },
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleAboutCommunityClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        sx={{
                          ".MuiMenu-list": {
                            padding: 0,
                          },
                        }}
                      >
                        <Box
                          className="navBoxMenu"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            fontFamily: "Comfortaa, sans-serif",
                            backgroundColor: "#09375c",
                          }}
                        >
                          {item.children.map((item) => (
                            <Link
                              className="aboutCommunityNavItem"
                              to={item.href}
                              style={{
                                textDecoration: "none",
                                color: "white ",
                                opacity: "1",
                                display: "flex",
                                width: "100%",
                                padding: "7px 0px",
                              }}
                            >
                              <span
                                onClick={handleAboutCommunityClose}
                                style={{
                                  textTransform: "uppercase",
                                  marginRight: 10,
                                  marginLeft: 10,
                                }}
                              >
                                {item.name}
                              </span>
                            </Link>
                          ))}
                        </Box>
                      </Menu>
                    </div>
                  );
                } else
                  return (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 500,
                        fontSize: { xs: 12, md: 14 },
                        textTransform: "uppercase",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Link
                        to={item.href}
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                          textTransform: "uppercase",
                        }}
                      >
                        {item.name}
                      </Link>
                    </Typography>
                  );
              })}
            </Stack>
            <Box
              sx={{ display: { xs: "none", sm: "flex", alignItems: "center" } }}
            >
              <Box
                component="img"
                sx={{
                  width: "100%",
                  height: 38,
                  marginLeft: 1,
                }}
                src={logoEU}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            width: "100%",
            flexGrow: 1,
            // marginTop: 5, //ako nema logo ? true
          }}
        >
          <Outlet />
        </Box>
        {openNotification && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={true}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={variant}>
              {message}
            </Alert>
          </Snackbar>
        )}
      </Box>
      <Box sx={{ backgroundColor: "#09375c", pt: 1, pb: 1 }}>
        <AuthFooter />
      </Box>
    </>
  );
}

PublicLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PublicLayout;
