import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import bgImg from "../../../../src/assets/img/communityBg.jpg";
import orgEN from "../../../../src/assets/img/orgEN.png";
import orgME from "../../../../src/assets/img/orgME.png";
import SchoolIcon from "@mui/icons-material/School";
import { useTranslation } from "react-i18next";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";

const AssociationComponent = () => {
  const { t } = useTranslation();
  const app_language = localStorage.getItem("lang-er");

  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
        }}
      />
      <Grid container sx={{ margin: { xs: 0, md: 2 } }}>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={11} mt={4}>
          <Typography variant="h2" mb={3}>
            {t("about_us.title")}
          </Typography>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={5} sx={{ padding: { xs: 3, md: 0 } }}>
          <Typography
            variant="body1"
            mb={2}
            sx={{ width: { xs: "100%", md: "85%" }, textAlign: "justify" }}
          >
            {t("about_us.firstParagraph_Text")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ width: { xs: "100%", md: "85%" }, textAlign: "justify" }}
            mb={3}
          >
            {t("about_us.secondParagraph_Text")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ width: { xs: "100%", md: "85%" }, textAlign: "justify" }}
            mb={3}
          >
            {t("about_us.thirdParagraph_Text")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ width: { xs: "100%", md: "85%" }, textAlign: "justify" }}
            mb={2}
          >
            {t("about_us.fourthParagraph_Text")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          mb={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 3, md: 0 },
          }}
        >
          {/* <Typography variant="h3" sx={{ ml: { xs: 0.5, md: 2 } }} mb={2}>
            {t("about_us.strategic_framework")}
          </Typography> */}
          <Divider component="li" />
          <List
            sx={{
              py: 0,
              width: "100%",
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider",
              backgroundColor: "background.paper",
            }}
          >
            <ListItemButton>
              <ListItemText
                primary={t("about_us.polices1")}
                sx={{ padding: "7px" }}
              />
              <CloudDownloadRoundedIcon />
            </ListItemButton>
            <Divider component="li" />
            <ListItemButton>
              <ListItemText
                primary={t("about_us.polices2")}
                sx={{ padding: "7px" }}
              />
              <CloudDownloadRoundedIcon />
            </ListItemButton>
          </List>
          {app_language === "en" ? (
            <Box
              style={{
                backgroundImage: `url(${orgEN})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "60vh",
              }}
            />
          ) : (
            <Box
              style={{
                backgroundImage: `url(${orgME})`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "60vh",
              }}
            />
          )}
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
      <Grid item md={12}></Grid>
      <Grid container sx={{ margin: { xs: 0, md: 2 } }}>
        <Grid item md={1}></Grid>
        <Grid item md={10} sx={{ padding: { xs: 3, md: 0 } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              mb: { xs: 0, md: 4 },
            }}
          >
            <Typography variant="h2">{t("association.title1")}</Typography>
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={10} mb={4} sx={{ padding: { xs: 3, md: 0 } }}>
          <Typography
            variant="body1"
            sx={{
              width: { xs: "100%", md: "85%" },
              textAlign: "justify",
              mb: { xs: 4, md: 2 },
            }}
          >
            {t("association.thirdParagraph_Text")}
          </Typography>
          <Stack
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Stack
              direction="column"
              spacing={2}
              sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 } }}
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
            >
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/af", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty1")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/btf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty2")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/ef", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty3")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/etf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty4")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/fdu", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty5")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/flu", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty6")}
                </Typography>
              </Button>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 } }}
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
            >
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/fpn", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty7")}
                </Typography>
              </Button>

              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/sport", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty8")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/fth", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty9")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/fil", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty10")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/ff", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty11")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/gf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty12")}
                </Typography>
              </Button>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ mr: { xs: 0, md: 3 }, mb: { xs: 2, md: 0 } }}
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
            >
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/mf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty13")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/med", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty14")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/mtf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty15")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/ma", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty16")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/pfkotor", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty17")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/pf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty18")}
                </Typography>
              </Button>
            </Stack>
            <Stack
              direction="column"
              spacing={2}
              sx={{ mr: { xs: 0, md: 3 } }}
              useFlexGap
              flexWrap="wrap"
              alignItems="center"
            >
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/pmf", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty19")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/ibm", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty20")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/ins", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty21")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/ii", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty22")}
                </Typography>
              </Button>
              <Button
                sx={{
                  width: 310,
                  height: 50,
                  justifyContent: "left",
                  alignItems: "left",
                }}
                variant="outlined"
                color="primary"
                startIcon={<SchoolIcon color="secondary" />}
                onClick={() =>
                  window.open("https://www.ucg.ac.me/cims", "_blank")
                }
              >
                <Typography
                  variant="overline"
                  style={{
                    color: "#09375c",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {t("association.faculty23")}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </>
  );
};

export default AssociationComponent;
