import Axios from "axios";

export const UserRepository = {
  fetchUsersBySearchParameters: (payload) => {
    return Axios({
      url: `/api/User/GetUsersBySearchParameters`,
      method: "GET",
      params: {
        FirstName: payload.firstName,
        LastName: payload.lastName,
        UserName: payload.userName,
        OrganizationalUnitId: payload.organizationalUnitId,
        RoleName: payload.roleName,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchUsersByOrgUnitParameters: (payload) => {
    return Axios({
      url: `/api/User/GetUsersByOrgUnitParameters`,
      method: "GET",
      params: {
        FirstName: payload.firstName,
        LastName: payload.lastName,
        UserName: payload.userName,
        OrganizationalUnitId: payload.organizationalUnitId,
        RoleName: payload.roleName,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  getSelfUserInfo: () => {
    return Axios.get(`/api/User/GetSelfUserInfo`);
  },

  fetchUserByID: (userId) => {
    return Axios.get(`/api/User/GetUserById/${userId}`);
  },

  //getUserReport PDF/Excel //ReportType === 1 PDF // ReportType === 2 Excel
  downloadExcel: async () => {
    const response = await Axios({
      url: `/api/Report/GetUsersReport`,
      method: "GET",
      responseType: "blob",
      params: {
        reportType: 2,
      },
    });
    const file = new Blob([response.data], {
      type: "application/vnd.ms-excel",
    });
    const fileURL = URL.createObjectURL(file);
    window.location.href = fileURL;
  },

  downloadPDF: async () => {
    const response = await Axios({
      url: `/api/Report/GetUsersReport`,
      method: "GET",
      responseType: "blob",
      params: {
        reportType: 1,
      },
    });
    const file = new Blob([response.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  },

  createUser: (data) => {
    return Axios({
      url: `/api/User/CreateUser`,
      method: "POST",
      data: data,
    });
  },

  updateUser: (data, userId) => {
    return Axios({
      url: `/api/User/UpdateUser/${userId}`,
      method: "PUT",
      data: data,
    });
  },

  updateOrgUnit: (data, userId) => {
    return Axios({
      url: `/api/User/UpdateOrgUnit/${userId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteUser: (userId) => {
    return Axios.delete(`/api/User/DeleteUser/${userId}`);
  },
};
