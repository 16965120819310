import Axios from "axios";

export const GenderRepository = {
  fetchAllGenders: (payload) => {
    return Axios({
      url: `/api/Gender/GetAllGenders`,
      method: "GET",
      // params: {
      //   PageNumber: payload.page,
      //   PageSize: payload.size,
      // },
    });
  },

  fetchGenderByID: (genderId) => {
    return Axios.get(`/api/Gender/GetGenderById/${genderId}`);
  },

  createGender: (data) => {
    return Axios({
      url: `/api/Gender/CreateGender`,
      method: "POST",
      data: data,
    });
  },

  updateGender: (data, genderId) => {
    return Axios({
      url: `/api/Gender/UpdateGender/${genderId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteGender: (genderId) => {
    return Axios.delete(`/api/Gender/DeleteGender/${genderId}`);
  },
};
