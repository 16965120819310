import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import bgImg from "../../../assets/img/recognitionBg.jpg";
import award2 from "../../../assets/img/award2.jpg";
import award3 from "../../../assets/img/award3.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

const RecognitionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
        }}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item={12} mt={4}>
          <Typography variant="h2" mb={3}>
            {t("homepage.recognition")}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        marginBottom={12}
        marginTop={2}
        spacing={5}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item={3}>
          <Stack
            sx={{
              width: 230,
              height: 300,
              backgroundColor: "#ebebeb",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <PersonIcon sx={{ fontSize: 180, color: "#cbcbcb" }} />
          </Stack>
        </Grid>
        <Grid item={3}>
          <Stack
            sx={{
              width: 230,
              height: 300,
              backgroundColor: "#ebebeb",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <PersonIcon sx={{ fontSize: 180, color: "#cbcbcb" }} />
          </Stack>
        </Grid>
        <Grid item={3}>
          <Stack
            sx={{
              width: 230,
              height: 300,
              backgroundColor: "#ebebeb",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <PersonIcon sx={{ fontSize: 180, color: "#cbcbcb" }} />
          </Stack>
        </Grid>
        <Grid item={3}>
          <Stack
            sx={{
              width: 230,
              height: 300,
              backgroundColor: "#ebebeb",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <PersonIcon sx={{ fontSize: 180, color: "#cbcbcb" }} />
          </Stack>
        </Grid>
      </Grid>
      <Divider width={"55%"} style={{ margin: "auto" }} />
      <Grid
        container
        marginTop={5}
        marginBottom={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item sx={{ width: { xs: "100%", md: "60%" }, padding: { xs: 2, md: 0 } }}>
          <Typography variant="h2" mb={2} textAlign={"center"}>
            {t("recognition.title")}
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textAlign: "center",
            }}
            mb={2}
          >
            {t("recognition.firstParagraph_Text")}
          </Typography>
          {/* <Typography variant="body1" mb={1}>
            {t("recognition.secondParagraph_Text")}
          </Typography>
          <Typography variant="body1" mb={1}>
            {t("recognition.thirdParagraph_Text")}
          </Typography>
          <Typography variant="body1" mb={1}>
            {t("recognition.fourthParagraph_Text")}
          </Typography>
          <Link mb={2} to={"/"}>
            {t("recognition.submit")}
          </Link> */}
          <Typography
            variant="body1"
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              textAlign: "center",
            }}
            mt={1}
            mb={1}
          >
            {t("recognition.fiveParagraph_Text")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default RecognitionComponent;
