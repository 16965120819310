import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import Moment from "react-moment";
import { JobsRepository } from "./JobsRepository";
import PendingJobDetails from "./PendingJobDetails";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

export default function PendingJobs() {
  const { t } = useTranslation();

  const [jobs, setJobs] = useState();
  const [companyName, setCompanyName] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [jobLocation, setJobLocation] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [openJobDetails, setOpenJobDetails] = useState(false);
  const [currentJobId, setCurrentJobId] = useState();

  const [pagination, setPagination] = useState({ TotalCount: 0 });
  const [loader, setLoader] = useState(true);
  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadJobs();
  }, [triggerRerender, page, size, companyName, jobTitle, jobLocation]);

  const loadJobs = () => {
    JobsRepository.fetchJobsBySearchParameters({
      companyName: companyName,
      jobTitle: jobTitle,
      jobLocation: jobLocation,
      status: 1,
      page: page,
      size: size,
    })
      .then((res) => {
        setJobs(res?.data);
        setPagination(JSON.parse(res.headers.x_pagination));
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setJobs({ totalCount: 0 });
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setSize(parseInt(event.target.value, 10));
  };

  return (
    <Grid container>
      {loader && <LoadingSpinner />}
      {openJobDetails && (
        <PendingJobDetails
          open={openJobDetails}
          setOpen={setOpenJobDetails}
          jobId={currentJobId}
        />
      )}

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item></Grid>
        <Grid item></Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "31%", paddingLeft: 2 }}>
                  <TextField
                    label={t("jobs.company_name")}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setTimeout(() => {
                        setCompanyName(e.target.value);
                        setPage(1);
                      }, 1500);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: "31%" }}>
                  <TextField
                    label={t("jobs.title")}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setTimeout(() => {
                        setJobTitle(e.target.value);
                        setPage(1);
                      }, 1500);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: "31%" }}>
                  <TextField
                    label={t("jobs.location")}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setTimeout(() => {
                        setJobLocation(e.target.value);
                        setPage(1);
                      }, 1500);
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: "6%" }}>{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs?.map((row) => (
                <TableRow
                  key={row.jobId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ paddingLeft: 2 }}>
                    {row.companyName}
                  </TableCell>
                  <TableCell>{row?.jobTitle}</TableCell>
                  <TableCell>{row.jobLocation}</TableCell>
                  <TableCell>
                    <Tooltip title={t("details")} aria-label={t("details")}>
                      <IconButton
                        color="info"
                        size="small"
                        onClick={() => {
                          setOpenJobDetails(true);
                          setCurrentJobId(row?.jobId);
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={9}
                  count={pagination?.TotalCount}
                  rowsPerPage={size}
                  page={page - 1}
                  labelRowsPerPage={t("rows_per_page")}
                  SelectProps={{
                    inputProps: { "aria-label": t("rows_per_page") },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>

      <Outlet />
    </Grid>
  );
}
