import Axios from "axios";

export const PublicNewsRepository = {
  //fetch (for admin)
  fetchBySearchParameters: (payload) => {
    return Axios({
      url: `/api/PublicNews/GetPublicNewsBySearchParameters`,
      method: "GET",
      params: {
        OrganizationalUnitId: payload.organizationalUnitId,
        Status: payload.status,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  //news for public-pages
  fetchPublicNews: (payload) => {
    return Axios({
      url: `/api/PublicNews/GetPublicNews`,
      method: "GET",
      params: {
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  //recent news for public-pages
  fetchRecentPublicNews: () => {
    return Axios.get(`/api/PublicNews/GetRecentPublicNews`);
  },

  fetchById: (publicNewsId) => {
    return Axios.get(`/api/PublicNews/GetPublicNewsById/${publicNewsId}`);
  },

  create: (data) => {
    return Axios({
      url: `/api/PublicNews/CreatePublicNews`,
      method: "POST",
      data: data,
    });
  },

  update: (data, publicNewsId) => {
    return Axios({
      url: `/api/PublicNews/UpdatePublicNews/${publicNewsId}`,
      method: "PUT",
      data: data,
    });
  },

  delete: (publicNewsId) => {
    return Axios.delete(`/api/PublicNews/DeletePublicNews/${publicNewsId}`);
  },

  approve: (publicNewsId) => {
    return Axios({
      url: `/api/PublicNews/ApprovePublicNews/${publicNewsId}`,
      method: "PUT",
    });
  },

  reject: (publicNewsId) => {
    return Axios({
      url: `/api/PublicNews/RejectPublicNews/${publicNewsId}`,
      method: "PUT",
    });
  },
};
