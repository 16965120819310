import { useRoutes } from "react-router-dom";

// project import
import MainRoutes from "./MainRoutes";
import PublicRoutes from "./PublicRoutes";
import NotFoundRoute from "./NotFoundRoute";
import LoginRoutes from "./LoginRoutes";
import AdminRoutes from "./AdminRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([MainRoutes, LoginRoutes, PublicRoutes, NotFoundRoute, AdminRoutes]);
}
