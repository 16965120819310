// material-ui
import { Box, IconButton, Link, useMediaQuery } from "@mui/material";

// project import
import Profile from "./Profile";
import MobileSection from "./MobileSection";
import { AuthService } from "auth/AuthService";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isAuthenticated = AuthService.getToken();

  return (
    <>
      <Box sx={{ width: "100%", ml: { xs: 0, md: 1 }, paddingX: 5 }}></Box>
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {isAuthenticated && <Profile />}
      {/* {!matchesXs && isAuthenticated && <Profile />}
      {matchesXs && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
