import Axios from "axios";

export const EventsRepository = {
  fetchEventsBySearchParameters: (payload) => {
    return Axios({
      url: `/api/Event/GetEventsBySearchParameters`,
      method: "GET",
      params: {
        EventName: payload.eventName,
        EventDate: payload.eventDate,
        EventTypeId: payload.eventTypeId,
        Status: payload.status,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchEventById: (eventId) => {
    return Axios.get(`/api/Event/GetEventById/${eventId}`);
  },

  createEvent: (data) => {
    return Axios({
      url: `/api/Event/CreateEvent`,
      method: "POST",
      data: data,
    });
  },

  updateEvent: (data, eventId) => {
    return Axios({
      url: `/api/Event/UpdateEvent/${eventId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteEvent: (eventId) => {
    return Axios.delete(`/api/Event/DeleteEvent/${eventId}`);
  },

  createEventMember: (data) => {
    return Axios({
      url: `/api/EventMember/CreateEventMember`,
      method: "POST",
      data: data,
    });
  },

  deleteEventMember: (eventMemberId) => {
    return Axios.delete(`/api/EventMember/DeleteEventMember/${eventMemberId}`);
  },

  approveEvent: (eventId) => {
    return Axios({
      url: `/api/Event/ApproveEvent/${eventId}`,
      method: "PUT",
    });
  },

  rejectEvent: (eventId) => {
    return Axios({
      url: `/api/Event/RejectEvent/${eventId}`,
      method: "PUT",
    });
  },
};
