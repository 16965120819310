import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  ButtonBase,
  Paper,
  Typography,
  Menu,
  MenuItem,
  Button,
  Badge,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { rerender } from "store/reducers/app";
import EmailIcon from "@mui/icons-material/Email";

import img1 from "assets/img/man3.jpg";
import img2 from "assets/img/man4.jpg";

import { AuthService } from "auth/AuthService";
import { Link } from "react-router-dom";
import { ChatRepository } from "./ChatRepository";

export default function ChatNotifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const userToken = AuthService.getDecodedUserJWT();
  const mainLayout = window.location.pathname.includes("app");

  const [messages, setMessages] = useState([]);

  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadUnreadChats();
  }, [triggerRerender]);

  const loadUnreadChats = () => {
    ChatRepository.fetchAllUnreadChats()
      .then((res) => {
        setMessages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{
          border: "none",
          color: userToken && !mainLayout ? "#fff" : "#09375c",
          padding: 0,
          minWidth: 48,
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Badge badgeContent={messages.length || 0} color="primary">
          <EmailIcon />
        </Badge>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ marginTop: 0.8, ul: { padding: 0 } }}
      >
        <Paper
          sx={{
            padding: 2,
            width: 300,
            maxHeight: 400,
            overflowY: "auto",

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {messages.map((message) => (
            <Box
              key={message.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: 1,
                padding: "0.5rem",
              }}
            >
              <Link
                to={`/app/chats`}
                state={{ unreadMessageChat: message }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <Avatar
                  alt="Avatar"
                  src={message.avatar}
                  sx={{ marginRight: 1, width: 48, height: 48 }}
                />
                <Stack>
                  <Typography variant="body1" fontWeight={"bold"}>
                    {userToken?.ID === message.userFrom.id
                      ? `${message.userTo.firstName} ${message.userTo.lastName}`
                      : `${message.userFrom.firstName} ${message.userFrom.lastName}`}
                  </Typography>
                  <Stack direction={"row"} alignItems={"center"} spacing={2}>
                    <Typography variant="caption">{"Nova poruka"}</Typography>
                  </Stack>
                </Stack>
              </Link>
            </Box>
          ))}
          {messages.length === 0 && (
            <Typography variant="body2" color="textSecondary" align="center">
              Nema novih poruka!
            </Typography>
          )}

          <Link
            to={`/app/chats`}
            state={{ selectedChat: null }}
            style={{
              textDecoration: "none",
              color: "inherit",
              textAlign: "center",
            }}
          >
            <Button variant="text" sx={{ textTransform: "initial" }}>
              Pregledajte sve poruke
            </Button>
          </Link>
        </Paper>
      </Menu>
    </>
  );
}
