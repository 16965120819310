import Axios from "axios";

export const JobsRepository = {
  fetchJobsBySearchParameters: (payload) => {
    return Axios({
      url: `/api/Job/GetJobsBySearchParameters`,
      method: "GET",
      params: {
        CompanyName: payload.companyName,
        JobTitle: payload.jobTitle,
        JobLocation: payload.jobLocation,
        Status: payload.status,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchRecentJobs: () => {
    return Axios.get(`/api/Job/GetRecentJobs`);
  },

  fetchJobById: (jobId) => {
    return Axios.get(`/api/Job/GetJobById/${jobId}`);
  },

  createJob: (data) => {
    return Axios({
      url: `/api/Job/CreateJob`,
      method: "POST",
      data: data,
    });
  },

  updateJob: (data, jobId) => {
    return Axios({
      url: `/api/Job/UpdateJob/${jobId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteJob: (jobId) => {
    return Axios.delete(`/api/Job/DeleteJob/${jobId}`);
  },

  approveJob: (jobId) => {
    return Axios({
      url: `/api/Job/ApproveJob/${jobId}`,
      method: "PUT",
    });
  },

  rejectJob: (jobId) => {
    return Axios({
      url: `/api/Job/RejectJob/${jobId}`,
      method: "PUT",
    });
  },
};
