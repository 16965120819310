import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { JobsRepository } from "./JobsRepository";
import { notifyShowSuccessMessage, rerender } from "store/reducers/app";
import { Link, Navigate } from "react-router-dom";
import InputUploadJobLogo from "components/InputUploadJobLogo";
import ValidateJob from "./JobValidation";
import { yupResolver } from "@hookform/resolvers/yup";

export default function CreateJobOpening() {
  const { t } = useTranslation();

  const [jobLogoFile, setJobLogoFile] = useState([]);

  const [redirectTo, setRedirectTo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ValidateJob()),
  });

  const createJob = (values) => {
    showLoadingBar();

    values.jobLogo = jobLogoFile[0];

    JobsRepository.createJob(values)
      .then((res) => {
        setRedirectTo(`/app/jobs`);
        dispatch(rerender());
        dispatch(
          notifyShowSuccessMessage({
            message: t("jobs.create_success"),
          })
        );
        hideLoadingBar();
      })
      .catch((err) => {
        hideLoadingBar();
      });
  };

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} />}
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item>
          <Link to={`/app/jobs`} style={{ display: "inline" }}>
            <Avatar sx={{ bgcolor: "white" }}>
              <ArrowBackIosNewIcon sx={{ color: "grey" }} />
            </Avatar>
          </Link>
        </Grid>
        <Grid item md={11}>
          <Typography variant="h5">{t("jobs.create")}</Typography>
        </Grid>
      </Grid>
      <Box component={Paper} padding={3} marginTop={2}>
        <form onSubmit={handleSubmit(createJob)}>
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              label={t("jobs.company_name")}
              fullWidth
              name="companyName"
              error={errors?.companyName}
              helperText={errors?.companyName?.message}
              {...register("companyName")}
            />
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              label={t("jobs.title")}
              name="jobTitle"
              error={errors?.jobTitle}
              helperText={errors?.jobTitle?.message}
              fullWidth
              {...register("jobTitle")}
            />
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              label={t("jobs.location")}
              name="jobLocation"
              error={errors?.jobLocation}
              helperText={errors?.jobLocation?.message}
              fullWidth
              {...register("jobLocation")}
            />
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              label={t("jobs.url")}
              name="jobURL"
              error={errors?.jobURL}
              helperText={errors?.jobURL?.message}
              fullWidth
              {...register("jobURL")}
            />
          </Box>
          <Box sx={{ marginBottom: 2 }}>
            <TextField
              label={t("jobs.description")}
              name="description"
              fullWidth
              {...register("description")}
            />
          </Box>
          <Box>
            <InputUploadJobLogo uploadedFiles={jobLogoFile} />
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            marginTop={3}
          >
            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ textTransform: "initial" }}
            >
              {t("jobs.create_job")}
            </Button>
            <Link to={"/app/jobs"} style={{ textDecoration: "none" }}>
              <Button variant="outlined" color="secondary">
                {t("cancel")}
              </Button>
            </Link>
          </Stack>
        </form>
      </Box>
    </>
  );
}
