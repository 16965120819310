import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import ProfileSettingsValidation from "./ProfileSettingsValidation";
import { AuthService } from "auth/AuthService";
import { ProfileSettingsRepository } from "./ProfileSettingsRepository";
import { notifyShowErrorMessage, notifyShowSuccessMessage } from "store/reducers/app";
import { useDispatch } from "react-redux";
import { AuthRepository } from "auth/AuthRepository";

const ProfileSettings = ({ isOpen, handleIsOpen }) => {
  const { t } = useTranslation();
  const data = AuthService.getDecodedUserJWT();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [userSettings, setUserSettings] = useState();

  const showLoadingBar = () => {
    setLoading(true);
  };

  const hideLoadingBar = () => {
    setLoading(false);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(ProfileSettingsValidation()),
  });

  const updateAccountPassword = (values) => {
    showLoadingBar();
    ProfileSettingsRepository.changeUserPassword(values, parseInt(data?.ID))
      .then((res) => {
        dispatch(
          notifyShowSuccessMessage({
            message: t("users.password_change_success"),
          })
        );
        hideLoadingBar();
        handleIsOpen(false);
        AuthRepository.logout();
      })
      .catch((err) => {
        dispatch(
          notifyShowErrorMessage({
            message: "ERROR!",
          })
        );
        hideLoadingBar();
      });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "unset",
          },
        }}
        style={{ borderRadius: 0 }}
      >
        <form onSubmit={handleSubmit(updateAccountPassword)}>
          {loading ? <LinearProgress color="primary" /> : ""}
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5">{t("users.change_password")}</Typography>
              <IconButton
                color="primary"
                onClick={() => {
                  handleIsOpen(false);
                }}
              >
                <HighlightOffIcon fontSize="large" />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs={12}>
              <Stack spacing={2}>
                <TextField
                  label={t("users.old_password")}
                  variant="outlined"
                  error={errors?.oldPassword}
                  helperText={errors?.oldPassword?.message}
                  name="oldPassword"
                  type={"password"}
                  {...register("oldPassword")}
                />
                <TextField
                  label={t("users.new_password")}
                  variant="outlined"
                  error={errors?.newPassword}
                  helperText={errors?.newPassword?.message}
                  name="newPassword"
                  type={"password"}
                  {...register("newPassword")}
                />
                <TextField
                  label={t("users.confirm_new_password")}
                  variant="outlined"
                  error={errors?.confirmNewPassword}
                  helperText={errors?.confirmNewPassword?.message}
                  name="confirmNewPassword"
                  type={"password"}
                  {...register("confirmNewPassword")}
                />
              </Stack>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Button variant="contained" type="submit">
                {t("save")}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  handleIsOpen(false);
                }}
              >
                {t("cancel")}
              </Button>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ProfileSettings;
