import Axios from "axios";

export const CountryRepository = {
  fetchBySearchParameters: (payload) => {
    return Axios({
      url: `/api/Country/GetCountriesBySearchParameters`,
      method: "GET",
      params: {
        Code: payload.code,
        NameME: payload.nameME,
        NameEN: payload.nameEN,
        PageNumber: payload.page,
        PageSize: payload.size,
        OrderBy: payload.orderBy,
      },
    });
  },

  fetchAll: () => {
    return Axios.get(`/api/Country/GetAllCountries`);
  },

  fetchById: (id) => {
    return Axios.get(`/api/Country/GetCountryById/${id}`);
  },

  create: (data) => {
    return Axios({
      url: `/api/Country/CreateCountry`,
      method: "POST",
      data: data,
    });
  },

  update: (data, id) => {
    return Axios({
      url: `/api/Country/UpdateCountry/${id}`,
      method: "PUT",
      data: data,
    });
  },

  delete: (id) => {
    return Axios.delete(`/api/Country/DeleteCountry/${id}`);
  },
};
