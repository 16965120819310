import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TodayIcon from "@mui/icons-material/Today";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import PeopleIcon from "@mui/icons-material/People";
import blankEvent from "assets/img/blank_event.jpg";

import { Link, useParams } from "react-router-dom";
import { notifyShowErrorMessage, notifyShowSuccessMessage, rerender } from "store/reducers/app";
import { dispatch } from "store";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import moment from "moment";
import { EventsRepository } from "./EventsRepository";
import Moment from "react-moment";
import { AuthService } from "auth/AuthService";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";

export default function EventDetailsComponent() {
  const { t } = useTranslation();

  const params = useParams();
  const [loader, setLoader] = useState(true);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [values, setValues] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const { triggerRerender } = useSelector((state) => state.app);

  const userId = AuthService.getDecodedUserJWT().ID;

  useEffect(() => {
    const loadEvent = (id) => {
      setLoadingMembers(true);
      EventsRepository.fetchEventById(id)
        .then((res) => {
          setValues(res.data);
          setLoader(false);
          setLoadingMembers(false);
        })
        .catch((err) => {
          dispatch(notifyShowErrorMessage({ message: err?.response?.data }));
        });
    };
    loadEvent(params.eventId);
  }, [triggerRerender, dispatch, params.eventId]);

  const createEventMember = (values) => {
    showLoadingBar();

    values.userAccountId = userId;
    values.eventId = JSON.parse(params.eventId);

    EventsRepository.createEventMember(values)
      .then((res) => {
        setRedirectTo(`/app/events`);
        dispatch(rerender());
        dispatch(
          notifyShowSuccessMessage({
            message: t("events.sign_up_success"),
          })
        );
        hideLoadingBar();
      })
      .catch((err) => {
        hideLoadingBar();
      });
  };

  const deleteEventMember = (id) => {
    showLoadingBar();

    EventsRepository.deleteEventMember(id)
      .then((res) => {
        hideLoadingBar();
        setRedirectTo(`/app/admin/administration/event-types`);
        dispatch(rerender());
        dispatch(notifyShowSuccessMessage({ message: t("events.cancel_success") }));
      })
      .catch((err) => {
        hideLoadingBar();
        dispatch(notifyShowErrorMessage({ message: "ERROR" }));
      });
  };

  const showLoadingBar = () => {
    setLoader(true);
  };

  const hideLoadingBar = () => {
    setLoader(false);
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(EventValidation()),
  });

  const findUser = values?.eventMembers.find((item) => item.userAccount.id == userId);

  return (
    <>
      {loader && <LoadingSpinner />}
      <Grid container marginBottom={3} alignItems={"center"}>
        <Grid item>
          <Link to={`/app/events`} style={{ display: "inline" }}>
            <Avatar sx={{ bgcolor: "white" }}>
              <ArrowBackIosNewIcon sx={{ color: "grey" }} />
            </Avatar>
          </Link>
        </Grid>
        <Grid item md={11}>
          <Typography variant="h3" fontStyle={"italic"}>
            {values?.eventName}
          </Typography>
        </Grid>
      </Grid>

      <Paper
        elevation={3}
        sx={{
          borderRadius: "8px",
          padding: 2,
        }}
      >
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item md={12}>
            <img
              src={values?.eventImage ? `data:image/jpeg;base64,${values?.eventImage}` : blankEvent}
              alt="Event Image"
              style={{ width: "100%", height: 350 }}
            />
          </Grid>

          <Grid
            item
            container
            md={12}
            alignItems={"center"}
            spacing={2}
            justifyContent={"space-between"}
          >
            <Grid item md={4}>
              <Stack direction={"row"} spacing={3}>
                <Stack direction={"row"} alignItems={"center"}>
                  <TodayIcon
                    sx={{
                      color: "#09375c",
                      fontSize: 25,
                      marginRight: 0.5,
                    }}
                  />
                  <Typography fontSize={15}>
                    <Moment format="DD.MM.YYYY">{values?.eventDate}</Moment>
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"}>
                  <AccessTimeIcon
                    sx={{
                      color: "#09375c",
                      fontSize: 25,
                      marginRight: 0.5,
                    }}
                  />
                  <Typography fontSize={15}>
                    <Moment format="HH:mm">{values?.eventDate}</Moment>
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{
                    color:
                      values?.eventMembers?.length >= values?.numberOfGuest ? "#d42121" : "green",
                    display: values?.isPublic === false ? "flex" : "none",
                  }}
                >
                  <PeopleIcon
                    sx={{
                      fontSize: 25,
                      marginRight: 0.5,
                    }}
                  />
                  {loadingMembers ? (
                    <MoonLoader size={14} color={"#09375c"} />
                  ) : (
                    <Typography fontSize={15}>{values?.eventMembers?.length}</Typography>
                  )}
                  <Typography fontSize={15}>/{values?.numberOfGuest}</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item md={7} display={"flex"} justifyContent={"flex-end"}>
              <Stack direction={"row"} alignItems={"center"}>
                <LocationOnIcon
                  sx={{
                    color: "#09375c",
                    fontSize: 25,
                    marginRight: 0.5,
                  }}
                />
                <Typography fontSize={15}>{values?.eventLocation}</Typography>
              </Stack>
            </Grid>
          </Grid>

          <Grid item md={12} marginTop={1}>
            <Typography fontSize={14} textAlign={"justify"}>
              {values?.description}
            </Typography>
          </Grid>

          <Grid item md={12} marginTop={1} display={"flex"} justifyContent={"flex-end"}>
            {values?.eventMembers.includes(findUser) ? (
              <Button
                variant="contained"
                disabled={loadingMembers}
                sx={{
                  backgroundColor: "#d42121",
                  borderRadius: 10,
                  textTransform: "initial",
                  "&:hover": { backgroundColor: "#e33636" },
                }}
                onClick={() => deleteEventMember(findUser.eventMemberId)}
              >
                {t("events.cancel")}
              </Button>
            ) : (
              <form onSubmit={handleSubmit(createEventMember)}>
                <Button
                  variant="contained"
                  disabled={loadingMembers}
                  sx={{
                    backgroundColor: "#09375c",
                    borderRadius: 10,
                    textTransform: "initial",
                  }}
                  type="submit"
                >
                  {t("events.sign_up")}
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
