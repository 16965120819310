//mui
import { Dialog, DialogContent } from "@mui/material";
//spinner
import { RotateLoader } from "react-spinners";
import { loadingHashOverride } from "themes/custom-styles";

const LoadingSpinner = () => {
  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{
        style: {
          overflowY: "unset",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <DialogContent>
        <RotateLoader
          color={"#09375c"}
          cssOverride={loadingHashOverride}
          size={20}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingSpinner;
