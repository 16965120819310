import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import bgImg from "../../../assets/img/careerBg.jpg";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import { useTranslation } from "react-i18next";

const CareersComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "50vh",
        }}
      />
      <Grid container sx={{ margin: { xs: 0, md: 3 } }}>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={7} sx={{ padding: { xs: 3, md: 0 } }}>
          <Typography variant="h2" mb={3} sx={{ width: { xs: "100%", md: "80%" } }}>
            {t("homepage.careers")}
          </Typography>
          <Typography mb={2} sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }}>
            {t("career.firstParagraph_Text")}
          </Typography>
          <Typography sx={{ width: { xs: "100%", md: "75%" }, textAlign: "justify" }} mb={3}>
            {t("career.secondParagraph_Text")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: { xs: 3, md: 0 },
          }}
        >
          <Typography variant="h3" sx={{ ml: { xs: 0.5, md: 2 } }} mb={2}>
            {t("career.title1")}
          </Typography>
          <Divider component="li" />
          <List
            sx={{
              py: 0,
              width: "100%",
              maxWidth: { xs: "100%" },
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider",
              backgroundColor: "background.paper",
            }}
          >
            <ListItemButton>
              <ListItemText primary={t("career.item2")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>
            <Divider variant="middle" component="li" />
            <ListItemButton>
              <ListItemText primary={t("career.item")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>

            <Divider variant="middle" component="li" />
            <ListItemButton>
              <ListItemText primary={t("career.item1")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>
            <Divider variant="middle" component="li" />
            <ListItemButton>
              <ListItemText primary={t("career.item3")} sx={{ padding: "7px" }} />
              <NavigateNextSharpIcon />
            </ListItemButton>
          </List>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </>
  );
};

export default CareersComponent;
