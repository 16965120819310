import Axios from "axios";
import { SETTINGS } from "../properties/ApiProps";

export const AuthRepository = {
  fetchToken: (user) => {
    return Axios({
      url: "/api/Authentication/AuthenticateUser",
      method: "POST",
      data: user,
    });
  },

  refreshToken: () => {
    return Axios({
      url: "oauth/token",
      method: "POST",
      params: {
        refresh_token: JSON.parse(localStorage.getItem("accessToken")).refresh_token,
        grant_type: "refresh_token",
      },
      headers: {
        Authorization: SETTINGS.API_AUTHORIZATION_HEADER,
      },
    });
  },

  logout: () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    window.location.href = "/app/login";
  },

  registerUser: (data) => {
    return Axios({
      url: `/api/User/RegisterUser`,
      method: "POST",
      data: data,
    });
  },

  changeUserPassword: (data, userId) => {
    return Axios({
      url: `/api/User/ChangeUserPassword/${userId}`,
      method: "PUT",
      data: data,
    });
  },
};
