import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Stack,
  TextField,
  InputAdornment,
  Tooltip,
  LinearProgress,
  Pagination,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchIcon from "@mui/icons-material/Search";
import LinkIcon from "@mui/icons-material/Link";

import { useTranslation } from "react-i18next";
import { JobsRepository } from "./JobsRepository";
import { useSelector } from "react-redux";
import LoadingSpinner from "components/LoadingSpinner";

const JobAdComponent = ({
  jobId,
  companyName,
  jobTitle,
  jobLocation,
  jobURL,
  jobLogo,
  description,
}) => {
  const { t } = useTranslation();

  return (
    <Paper elevation={3} style={{ padding: "16px", marginBottom: "16px" }}>
      <Box display="flex" justifyContent={"space-between"} key={jobId}>
        <Box sx={{ flex: "0 0 8%" }}>
          <img
            src={`data:image/jpeg;base64,${jobLogo}`}
            style={{ width: 54, height: 54, marginRight: 3 }}
            alt="Job logo"
          />
        </Box>
        <Box sx={{ flex: "0 0 90%" }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            // alignItems={"center"}
          >
            <Box sx={{ flex: "0 0 80%" }}>
              <Typography variant="h6">{companyName}</Typography>
              <Typography variant="subtitle1">{jobTitle}</Typography>
              <Stack direction={"row"} alignItems={"center"}>
                <LocationOnIcon sx={{ fontSize: 16 }} />
                <Typography variant="subtitle2">{jobLocation}</Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                flex: "0 0 18%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip title={t("jobs.url")}>
                <a
                  href={jobURL}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#09375c",
                    display: "inline",
                    height: 20,
                  }}
                >
                  <LinkIcon sx={{ fontSize: "30px" }} />
                </a>
              </Tooltip>
            </Box>
          </Box>
          <Divider style={{ margin: "8px 0" }} />
          <Typography variant="body2">{description}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default function JobOpenings() {
  const { t } = useTranslation();

  const [jobs, setJobs] = useState();
  const [pagination, setPagination] = useState({ TotalCount: 0 });
  const [companyName, setCompanyName] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [jobLocation, setJobLocation] = useState();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [loader, setLoader] = useState(true);
  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadJobs();
  }, [triggerRerender, page, size, companyName, jobTitle, jobLocation]);

  const loadJobs = () => {
    JobsRepository.fetchJobsBySearchParameters({
      companyName: companyName,
      jobTitle: jobTitle,
      jobLocation: jobLocation,
      page: page,
      size: size,
    })
      .then((res) => {
        setJobs(res?.data);
        setPagination(JSON.parse(res.headers.x_pagination));
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setJobs({ totalCount: 0 });
        }
      });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      {loader && <LoadingSpinner />}
      <Paper elevation={3} sx={{ borderRadius: "8px" }}>
        {/* {loader ? <LinearProgress color="primary" /> : ""} */}
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            paddingBottom: 0.8,
            borderRadius: "8px",
            marginBottom: 3,
            display: jobs?.length > 0 ? "block" : "none",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <TextField
              label={t("jobs.company_name")}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTimeout(() => {
                  setCompanyName(e.target.value);
                  setPage(1);
                }, 1500);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t("jobs.title")}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTimeout(() => {
                  setJobTitle(e.target.value);
                  setPage(1);
                }, 1500);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={t("jobs.location")}
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setTimeout(() => {
                  setJobLocation(e.target.value);
                  setPage(1);
                }, 1500);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Typography
            variant="body2"
            fontWeight="bold"
            color="textSecondary"
            marginTop={0.5}
          >
            Ukupni poslovi: {jobs?.length}
          </Typography>
        </Box>
      </Paper>

      {jobs?.length > 0 ? (
        jobs?.map((job, index) => <JobAdComponent key={index} {...job} />)
      ) : jobs ? (
        <Box component={Paper} paddingX={2} paddingY={3} fontSize={"15px"}>
          {t("jobs.no_jobs_available")}!
        </Box>
      ) : (
        ""
      )}

      <Box
        display={jobs?.length > 0 ? "flex" : "none"}
        justifyContent={"right"}
        alignItems={"center"}
        marginTop={1}
      >
        <Pagination
          color="primary"
          count={pagination?.TotalPages}
          page={page}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
}
