import { lazy } from "react";
import { Navigate } from "react-router-dom";

// project import
import Loadable from "components/Loadable";
import { AuthService } from "auth/AuthService";
import AdminLayout from "layout/AdminLayout";

// render - dashboard
const CountryComponent = Loadable(
  lazy(() => import("pages/administration/country/CountryComponent"))
);
const CountryCreateComponent = Loadable(
  lazy(() => import("pages/administration/country/CountryCreateComponent"))
);
const CountryEditComponent = Loadable(
  lazy(() => import("pages/administration/country/CountryEditComponent"))
);
const CountryDeleteComponent = Loadable(
  lazy(() => import("pages/administration/country/CountryDeleteComponent"))
);

const UniversityComponent = Loadable(
  lazy(() => import("pages/administration/university/UniversityComponent"))
);
const UniversityCreateComponent = Loadable(
  lazy(() => import("pages/administration/university/UniversityCreateComponent"))
);
const UniversityEditComponent = Loadable(
  lazy(() => import("pages/administration/university/UniversityEditComponent"))
);
const UniversityDeleteComponent = Loadable(
  lazy(() => import("pages/administration/university/UniversityDeleteComponent"))
);

const OrganizationalUnitComponent = Loadable(
  lazy(() => import("pages/administration/organizationalUnit/OrganizationalUnitComponent"))
);
const OrganizationalUnitCreateComponent = Loadable(
  lazy(() => import("pages/administration/organizationalUnit/OrganizationalUnitCreateComponent"))
);
const OrganizationalUnitEditComponent = Loadable(
  lazy(() => import("pages/administration/organizationalUnit/OrganizationalUnitEditComponent"))
);
const OrganizationalUnitDeleteComponent = Loadable(
  lazy(() => import("pages/administration/organizationalUnit/OrganizationalUnitDeleteComponent"))
);

const GenderComponent = Loadable(lazy(() => import("pages/administration/gender/GenderComponent")));
const GenderCreateComponent = Loadable(
  lazy(() => import("pages/administration/gender/GenderCreateComponent"))
);
const GenderEditComponent = Loadable(
  lazy(() => import("pages/administration/gender/GenderEditComponent"))
);
const GenderDeleteComponent = Loadable(
  lazy(() => import("pages/administration/gender/GenderDeleteComponent"))
);

const MemberTypeComponent = Loadable(
  lazy(() => import("pages/administration/memberType/MemberTypeComponent"))
);
const MemberTypeCreateComponent = Loadable(
  lazy(() => import("pages/administration/memberType/MemberTypeCreateComponent"))
);
const MemberTypeEditComponent = Loadable(
  lazy(() => import("pages/administration/memberType/MemberTypeEditComponent"))
);
const MemberTypeDeleteComponent = Loadable(
  lazy(() => import("pages/administration/memberType/MemberTypeDeleteComponent"))
);

const OccupationComponent = Loadable(
  lazy(() => import("pages/administration/occupation/OccupationComponent"))
);
const OccupationCreateComponent = Loadable(
  lazy(() => import("pages/administration/occupation/OccupationCreateComponent"))
);
const OccupationEditComponent = Loadable(
  lazy(() => import("pages/administration/occupation/OccupationEditComponent"))
);
const OccupationDeleteComponent = Loadable(
  lazy(() => import("pages/administration/occupation/OccupationDeleteComponent"))
);

const EventTypeComponent = Loadable(
  lazy(() => import("pages/administration/eventType/EventTypeComponent"))
);
const EventTypeCreateComponent = Loadable(
  lazy(() => import("pages/administration/eventType/EventTypeCreateComponent"))
);
const EventTypeEditComponent = Loadable(
  lazy(() => import("pages/administration/eventType/EventTypeEditComponent"))
);
const EventTypeDeleteComponent = Loadable(
  lazy(() => import("pages/administration/eventType/EventTypeDeleteComponent"))
);

const StudyProgramComponent = Loadable(
  lazy(() => import("pages/administration/studyProgram/StudyProgramComponent"))
);
const StudyProgramCreateComponent = Loadable(
  lazy(() => import("pages/administration/studyProgram/StudyProgramCreateComponent"))
);
const StudyProgramEditComponent = Loadable(
  lazy(() => import("pages/administration/studyProgram/StudyProgramEditComponent"))
);
const StudyProgramDeleteComponent = Loadable(
  lazy(() => import("pages/administration/studyProgram/StudyProgramDeleteComponent"))
);

const StudyLevelComponent = Loadable(
  lazy(() => import("pages/administration/studyLevel/StudyLevelComponent"))
);
const StudyLevelCreateComponent = Loadable(
  lazy(() => import("pages/administration/studyLevel/StudyLevelCreateComponent"))
);
const StudyLevelEditComponent = Loadable(
  lazy(() => import("pages/administration/studyLevel/StudyLevelEditComponent"))
);
const StudyLevelDeleteComponent = Loadable(
  lazy(() => import("pages/administration/studyLevel/StudyLevelDeleteComponent"))
);

const PublicNews = Loadable(lazy(() => import("pages/public-news/PublicNews")));
const CreatePublicNews = Loadable(lazy(() => import("pages/public-news/CreatePublicNews")));
const PublicNewsEdit = Loadable(lazy(() => import("pages/public-news/PublicNewsEdit")));
const PublicNewsDelete = Loadable(lazy(() => import("pages/public-news/PublicNewsDelete")));
const PublicNewsConfirmation = Loadable(
  lazy(() => import("pages/public-news/PublicNewsConfirmation"))
);

const UserComponent = Loadable(lazy(() => import("pages/user/UserComponent")));
const UserCreateComponent = Loadable(lazy(() => import("pages/user/UserCreateComponent")));
const UserEditComponent = Loadable(lazy(() => import("pages/user/UserEditComponent")));
const UserDeleteComponent = Loadable(lazy(() => import("pages/user/UserDeleteComponent")));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
  path: "/app/admin",
  element: <AdminLayout />,
  children: [
    // USERS
    {
      path: "/app/admin/users",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <UserComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/users/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <UserCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/users/:id/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <UserEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/users/:id/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <UserDeleteComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/universities",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <UniversityComponent />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/admin/administration/universities/create",
          element: (
            <RequireAuth
              redirectTo="/app/admin/login"
              hasAccess="Members,Administrators,OrganizationAdministrator"
            >
              <UniversityCreateComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/admin/administration/universities/:universityId/edit",
          element: (
            <RequireAuth
              redirectTo="/app/admin/login"
              hasAccess="Members,Administrators,OrganizationAdministrator"
            >
              <UniversityEditComponent />
            </RequireAuth>
          ),
        },
        {
          path: "/app/admin/administration/universities/:universityId/delete",
          element: (
            <RequireAuth
              redirectTo="/app/admin/login"
              hasAccess="Members,Administrators,OrganizationAdministrator"
            >
              <UniversityDeleteComponent />
            </RequireAuth>
          ),
        },
      ],
    },

    //ORGANIZATIONAL UNITS
    {
      path: "/app/admin/administration/organizational-units",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <OrganizationalUnitComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/organizational-units/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <OrganizationalUnitCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/organizational-units/:organizationalUnitId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <OrganizationalUnitEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/organizational-units/:organizationalUnitId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <OrganizationalUnitDeleteComponent />
        </RequireAuth>
      ),
    },

    //STUDY PROGRAMS
    {
      path: "/app/admin/administration/study-programs",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyProgramComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/study-programs/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyProgramCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/study-programs/:studyProgramId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyProgramEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/study-programs/:studyProgramId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyProgramDeleteComponent />
        </RequireAuth>
      ),
    },

    //STUDY LEVELS
    {
      path: "/app/admin/administration/study-levels",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyLevelComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/study-levels/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyLevelCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/study-levels/:studyLevelId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyLevelEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/study-levels/:studyLevelId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <StudyLevelDeleteComponent />
        </RequireAuth>
      ),
    },

    //COUNTRIES
    {
      path: "/app/admin/administration/countries",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <CountryComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/countries/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <CountryCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/countries/:countryId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <CountryEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/countries/:countryId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Administrators,OrganizationAdministrator"
        >
          <CountryDeleteComponent />
        </RequireAuth>
      ),
    },

    //GENDERS
    {
      path: "/app/admin/administration/genders",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <GenderComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/genders/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <GenderCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/genders/:genderId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <GenderEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/genders/:genderId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <GenderDeleteComponent />
        </RequireAuth>
      ),
    },

    //MEMBER TYPES
    {
      path: "/app/admin/administration/member-types",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MemberTypeComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/member-types/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MemberTypeCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/member-types/:memberTypeId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MemberTypeEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/member-types/:memberTypeId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <MemberTypeDeleteComponent />
        </RequireAuth>
      ),
    },

    //OCCUPATION
    {
      path: "/app/admin/administration/occupations",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <OccupationComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/occupations/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <OccupationCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/occupations/:occupationId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <OccupationEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/occupations/:occupationId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <OccupationDeleteComponent />
        </RequireAuth>
      ),
    },

    //EVENT TYPES
    {
      path: "/app/admin/administration/event-types",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <EventTypeComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/event-types/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <EventTypeCreateComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/event-types/:eventTypeId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <EventTypeEditComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/administration/event-types/:eventTypeId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <EventTypeDeleteComponent />
        </RequireAuth>
      ),
    },
    {
      path: "/app/admin/public-news",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <PublicNews />
        </RequireAuth>
      ),
      children: [
        {
          path: "/app/admin/public-news/:publicNewsId/confirmation",
          element: (
            <RequireAuth
              redirectTo="/app/admin/login"
              hasAccess="Members,Administrators,OrganizationAdministrator"
            >
              <PublicNewsConfirmation />
            </RequireAuth>
          ),
        },
      ],
    },
    //create public news
    {
      path: "/app/admin/public-news/create",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <CreatePublicNews />
        </RequireAuth>
      ),
    },
    //edit public news
    {
      path: "/app/admin/public-news/:publicNewsId/edit",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <PublicNewsEdit />
        </RequireAuth>
      ),
    },
    //delete public news
    {
      path: "/app/admin/public-news/:publicNewsId/delete",
      element: (
        <RequireAuth
          redirectTo="/app/admin/login"
          hasAccess="Members,Administrators,OrganizationAdministrator"
        >
          <PublicNewsDelete />
        </RequireAuth>
      ),
    },
  ],
};

function RequireAuth({ children, redirectTo, hasAccess }) {
  const data = AuthService.getDecodedUserJWT();

  if (!data) {
    return <Navigate to={redirectTo} replace />;
  }

  const hasAccessArr = hasAccess.split(",");
  const roles = data?.Role;
  const userHasRequiredRole = hasAccessArr.some((item) => roles?.includes(item));

  if (!userHasRequiredRole) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
}

export default AdminRoutes;
