import React, { useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "moment/locale/sr"; // Import Serbian locale
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./AcademicCalendar.scss";

const CustomCalendar = () => {
  const nastava = ["2024-02-06", "2024-02-09", "2024-02-15"];
  const zavrsniIspiti = ["2024-03-12", "2024-03-10", "2024-03-15", "2024-03-05"];
  const praznici = ["2024-04-06", "2024-02-07"];
  const organizacija = ["2024-05-01", "2024-05-03"];
  const humanitarnaAkcija = ["2024-05-09"];
  const usvajanjeOcena = ["2024-06-11", "2024-06-12"];
  const kolektivniOdmor = ["2024-07-01", "2024-07-02", "2024-07-03", "2024-07-04", "2024-07-05"];

  const { t, i18n } = useTranslation();

  useEffect(() => {}, [localStorage.getItem("lang-er")]);

  const messages = {
    allDay: t("calendar.allDay"),
    previous: t("calendar.previous"),
    next: t("calendar.next"),
    today: t("calendar.today"),
    month: t("calendar.month"),
    week: t("calendar.week"),
    day: t("calendar.day"),
    agenda: t("calendar.agenda"),
    date: t("calendar.date"),
    time: t("calendar.time"),
    event: t("calendar.event"),
  };

  moment.locale(i18n.language);

  const localizer = momentLocalizer(moment);

  const customDayPropGetter = (date) => {
    if (nastava.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "nastava-day",
      };
    } else if (zavrsniIspiti.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "zavrsniIspiti-day",
      };
    } else if (praznici.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "praznici-day",
      };
    } else if (organizacija.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "organizacija-day",
      };
    } else if (humanitarnaAkcija.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "humanitarnaAkcija-day",
      };
    } else if (usvajanjeOcena.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "usvajanjeOcena-day",
      };
    } else if (kolektivniOdmor.includes(moment(date).format("YYYY-MM-DD"))) {
      return {
        className: "kolektivniOdmor-day",
      };
    } else return {};
  };

  return (
    <div className="calendar-container">
      <Calendar
        localizer={localizer}
        events={[]}
        startAccessor="start"
        endAccessor="end"
        dayPropGetter={customDayPropGetter}
        tooltipAccessor={(event) => "ZDR"}
        views={["month"]}
        messages={messages}
        // toolbar={false}
      />
    </div>
  );
};

export default CustomCalendar;
