import Axios from "axios";

export const StudyLevelRepository = {
  fetchAllStudyLevels: (payload) => {
    return Axios({
      url: `/api/Gender/GetAllStudyLevel`,
      method: "GET",
      params: {
        // PageNumber: payload.page,
        // PageSize: payload.size,
      },
    });
  },

  fetchStudyLevelByID: (studyLevelId) => {
    return Axios.get(`/api/Gender/GetStudyLevelById/${studyLevelId}`);
  },

  createStudyLevel: (data) => {
    return Axios({
      url: `/api/Gender/CreateStudyLevel`,
      method: "POST",
      data: data,
    });
  },

  updateStudyLevel: (data, studyLevelId) => {
    return Axios({
      url: `/api/Gender/UpdateStudyLevel/${studyLevelId}`,
      method: "PUT",
      data: data,
    });
  },

  deleteStudyLevel: (studyLevelId) => {
    return Axios.delete(`/api/Gender/DeleteStudyLevel/${studyLevelId}`);
  },
};
