import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";

// material-ui
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import { EventsRepository } from "./EventsRepository";
import { EventTypeRepository } from "pages/administration/eventType/EventTypeRepository";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import Moment from "react-moment";
import PendingEventDetails from "./PendingEventDetails";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

export default function PendingEvents() {
  const { t } = useTranslation();

  const [events, setEvents] = useState();
  const [eventName, setEventName] = useState();
  const [eventDate, setEventDate] = useState();
  const [eventTypes, setEventTypes] = useState();
  const [eventTypeId, setEventTypeId] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [openEventDetails, setOpenEventDetails] = useState(false);
  const [currentEventId, setCurrentEventId] = useState();

  const [pagination, setPagination] = useState({ TotalCount: 0 });
  const [loader, setLoader] = useState(true);
  const { triggerRerender } = useSelector((state) => state.app);

  useEffect(() => {
    loadEvents();
    loadEventTypes();
  }, [triggerRerender, page, size, eventName, eventDate, eventTypeId]);

  const loadEvents = () => {
    EventsRepository.fetchEventsBySearchParameters({
      eventName: eventName,
      eventDate: eventDate,
      eventTypeId: eventTypeId,
      status: 1,
      page: page,
      size: size,
    })
      .then((res) => {
        setEvents(res?.data);
        setPagination(JSON.parse(res.headers.x_pagination));
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log(err);
        }
      });
  };

  const loadEventTypes = () => {
    EventTypeRepository.fetchAllEventTypes()
      .then((res) => {
        setEventTypes(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          console.log("err");
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setSize(parseInt(event.target.value, 10));
  };

  return (
    <Grid container>
      {loader && <LoadingSpinner />}
      {openEventDetails && (
        <PendingEventDetails
          open={openEventDetails}
          setOpen={setOpenEventDetails}
          eventId={currentEventId}
        />
      )}

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item></Grid>
        <Grid item></Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1.5 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "31%", paddingLeft: 2 }}>
                  <TextField
                    label={t("events.name")}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      setTimeout(() => {
                        setEventName(e.target.value);
                        setPage(1);
                      }, 1500);
                    }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <SearchIcon />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </TableCell>
                <TableCell sx={{ width: "31%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={t("events.date")}
                      value={eventDate || null}
                      onChange={(date) => {
                        date != null
                          ? setEventDate(moment(date).format("YYYY-MM-DD"))
                          : setEventDate(null);
                      }}
                      inputFormat="dd.MM.yyyy"
                      renderInput={(props) => (
                        <TextField {...props} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </TableCell>
                <TableCell sx={{ width: "31%" }}>
                  <Autocomplete
                    fullWidth
                    options={eventTypes}
                    disabled={!eventTypes}
                    getOptionLabel={(option) => option?.nameME}
                    onChange={(event, item) => {
                      setEventTypeId(item?.eventTypeId);
                    }}
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === "" ||
                      option.eventTypeId === value.eventTypeId
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("events.event_type")}
                        sx={{
                          ".MuiOutlinedInput-root": {
                            padding: "4px",
                          },
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ width: "6%" }}>{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events?.map((row) => (
                <TableRow
                  key={row.eventId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ paddingLeft: 2 }}>
                    {row.eventName}
                  </TableCell>
                  <TableCell>
                    <Moment format="DD.MM.YYYY">{row?.eventDate}</Moment>
                  </TableCell>
                  <TableCell>{row.eventType.nameME}</TableCell>
                  <TableCell>
                    <Tooltip title={t("details")} aria-label={t("details")}>
                      <IconButton
                        color="info"
                        size="small"
                        onClick={() => {
                          setOpenEventDetails(true);
                          setCurrentEventId(row?.eventId);
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={9}
                  count={pagination?.TotalCount}
                  rowsPerPage={size}
                  page={page - 1}
                  labelRowsPerPage={t("rows_per_page")}
                  SelectProps={{
                    inputProps: { "aria-label": t("rows_per_page") },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>

      <Outlet />
    </Grid>
  );
}
