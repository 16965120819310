import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Snackbar, Toolbar, useMediaQuery, Grid } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import homeBg from "../../assets/img/homepage-bg.jpg";

// project import
// import Drawer from "./Drawer";
import Header from "./Header";
// import navigation from "menu-items";
// import Breadcrumbs from "components/@extended/Breadcrumbs";
import { openDrawer } from "store/reducers/menu";
import { clearNotifyMessage, notifyShowWarningMessage } from "store/reducers/app";
import { AuthService } from "auth/AuthService";
import DrawerCalendar from "./DrawerCalendar";
import DrawerContent from "./Drawer/DrawerContent";
import { useTranslation } from "react-i18next";
import AddOrgUnitToUser from "components/AddOrgUnitToUser";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MainLayout() {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { drawerOpen } = useSelector((state) => state.menu);
  const { openNotification, message, variant } = useSelector((state) => state.app);

  const isAuth = AuthService.getDecodedUserJWT();
  const userOrgUnit = AuthService.getDecodedUserJWT()?.OrgUnit;

  const [openAddOrgUnitToUser, setOpenAddOrgUnitToUser] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      window.location.href = "/app/login";
    }
    // if (isAuth && location.pathname === "/app") {
    //   navigate("/app/news-feed");
    // }
  }, [isAuth, location.pathname, navigate]);

  //redirect to edit -> check if orgUnit is true
  useEffect(() => {
    if (userOrgUnit === "") {
      setOpenAddOrgUnitToUser(true);
      dispatch(
        notifyShowWarningMessage({
          message: t("please_add_org_unit"),
        })
      );
    } else {
      setOpenAddOrgUnitToUser(false);
    }
  }, [window.location.href, userOrgUnit]);

  // if first time login without changed password (user created automatically)
  useEffect(() => {
    if (AuthService.getDecodedUserJWT()?.IsFirstLogin === "True") {
      navigate("/app/my-profile/change-password");
    }
  }, []);

  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media-wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const handleClose = () => {
    if (openNotification) {
      dispatch(clearNotifyMessage());
    }
  };

  return (
    <Box
      className="main-wrapper"
      sx={{
        display: "flex",
        // width: { lg: "50%", xl: "100%" },
        // width: { lg: "50%", xl: "100%" },
        // overflowX: "hidden",
        height: "100vh",
        marginTop: 15,
      }}
    >
      <AddOrgUnitToUser open={openAddOrgUnitToUser} handleOpen={setOpenAddOrgUnitToUser} />
      <Grid container minWidth={"100%"}>
        <Grid item md={1}></Grid>
        <Grid item md={10}>
          <Box
            alt={"banner"}
            sx={{
              minWidth: "100%",
              backgroundImage: `url(${homeBg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              paddingY: 13,
              borderRadius: "8px",
            }}
          />
          <Grid container>
            <Grid item md={2}>
              <DrawerContent />
            </Grid>
            <Grid item md={7}>
              <Box
                component="main"
                sx={{
                  // flexGrow: 1,
                  p: { xs: 2, sm: 3 },
                  width: { md: "100%", xl: "100%" },
                  overflowY: "auto", // Allow the main content to scroll if needed
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {/* <Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} /> */}
                <Outlet />
              </Box>
            </Grid>
            <Grid item md={3}>
              <DrawerCalendar />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {/* <Drawer open={open} handleDrawerToggle={handleDrawerToggle} /> */}

      {/* Right Side Content */}
      {/* <DrawerCalendar /> */}

      {/* <DrawerContent/> */}

      {openNotification && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={variant}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
