import * as React from "react";
import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Stack,
  Typography,
} from "@mui/material";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GoogleIcon from "assets/img/google_logo.svg";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { useDispatch } from "react-redux";
import { notifyShowErrorMessage } from "store/reducers/app";
import { useTranslation } from "react-i18next";

export default function RegistrationModal({ setOpenRegistrationModal, openRegistrationModal }) {
  const { t } = useTranslation();

  const [redirectTo, setRedirectTo] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  //Google api handler
  useEffect(() => {
    // Load Google Sign-In API script
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      // Initialize Google Sign-In API
      window.gapi.load("auth2", () => {
        window.gapi.auth2.init({
          client_id: "YOUR_GOOGLE_CLIENT_ID",
        });
      });
    };
    document.head.appendChild(script);

    return () => {
      // Clean up the script on component unmount
      document.head.removeChild(script);
    };
  }, []);

  const handleLinkedInLogin = () => {
    console.log("LinkedIn login clicked");
    window.location.href = "/app/register";
  };

  const handleGoogleLogin = () => {
    console.log("Google login clicked");
    // // Trigger Google Sign-In API popup
    // const auth2 = window.gapi.auth2.getAuthInstance();
    // auth2.signIn().then((googleUser) => {
    //   // Handle successful Google login
    //   const profile = googleUser.getBasicProfile();
    //   console.log("ID: " + profile.getId());
    //   console.log("Name: " + profile.getName());
    //   console.log("Image URL: " + profile.getImageUrl());
    //   console.log("Email: " + profile.getEmail());
    // });
  };

  const handleFacebookLogin = () => {
    console.log("Facebook login clicked");
  };

  const handleEmailLogin = () => {
    console.log("Email login clicked");
  };

  return (
    <>
      {redirectTo && <Navigate to={redirectTo} />}
      <Dialog
        open={openRegistrationModal}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{
          style: {
            overflowY: "auto",
          },
        }}
        style={{ borderRadius: 0 }}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5"></Typography>
            <IconButton
              color="primary"
              onClick={() => {
                setOpenRegistrationModal(false);
              }}
            >
              <HighlightOffIcon fontSize="large" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: 2,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                width: "60%",
                fontSize: 15,
                color: "gray",
                marginBottom: 2,
              }}
            >
              <span style={{ fontWeight: "bold" }}>{t("register_welcome")}</span> <br />
              {t("register_welcome_join")}
            </Typography>
            <Link to={`/app/register`}>
              <Button
                variant="contained"
                color="primary"
                // onClick={handleLinkedInLogin}
                sx={{
                  width: 300,
                  borderRadius: 5,
                  margin: 1,
                  textTransform: "none",
                  backgroundColor: "#0077B5",
                  paddingY: 1.1,
                  color: "#f3f3f3",
                  "&:hover": {
                    backgroundColor: "#0077B5",
                  },
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <LinkedInIcon />
                  <span>{t("join_with_linkedin")}</span>
                </Stack>
              </Button>
            </Link>

            <Link to={`/app/register`}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoogleLogin}
                sx={{
                  width: 300,
                  borderRadius: 5,
                  margin: 1,
                  textTransform: "none",
                  backgroundColor: "transparent",
                  color: "gray",
                  border: "1px solid gray",
                  paddingY: 1.1,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Stack direction={"row"} alignItems={"center"} spacing={1}>
                  <img
                    src={GoogleIcon}
                    alt="googlelogin"
                    style={{ width: 20, marginBottom: "1px" }}
                  />
                  <span>{t("join_with_google")}</span>
                </Stack>
              </Button>
            </Link>
            <Link to={`/app/register`}>
              <Button
                variant="contained"
                color="primary"
                // onClick={handleFacebookLogin}
                sx={{
                  width: 300,
                  borderRadius: 5,
                  margin: 1,
                  textTransform: "none",
                  backgroundColor: "#4267B2",
                  color: "#f3f3f3",
                  paddingY: 1.1,
                  "&:hover": {
                    backgroundColor: "#4267B2",
                  },
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <FacebookIcon />
                  <span>{t("join_with_facebook")}</span>
                </Stack>
              </Button>
            </Link>

            <Typography>{t("or")}</Typography>

            <Link to={`/app/register`}>
              <Button
                variant="contained"
                color="primary"
                // onClick={handleEmailLogin}
                sx={{
                  width: 300,
                  borderRadius: 5,
                  margin: 1,
                  textTransform: "none",
                  backgroundColor: "#09375c",
                  color: "#f3f3f3",
                  paddingY: 1.1,
                  marginBottom: 3,
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <EmailIcon />
                  <span>{t("join_with_email")}</span>
                </Stack>
              </Button>
            </Link>

            <Link to={`/app/login`} style={{ color: "#09375c", fontWeight: "400" }}>
              {t("already_have_account")}
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
