import { lazy } from "react";

// project import
import Loadable from "components/Loadable";

// render - login
const NotFoundPage = Loadable(lazy(() => import("components/NotFoundPage")));

// ==============================|| AUTH NO-ROUTING ||============================== //

const NotFoundRoute = {
  path: "*",
  element: <NotFoundPage />,
};

export default NotFoundRoute;
