import { useTranslation } from "react-i18next";
import * as yup from "yup";

export default function EventValidation() {
  const { t } = useTranslation();

  return yup.object().shape({
    eventName: yup.string().required(t("events.name") + t("required_field")),
    description: yup
      .string()
      .required(t("events.description") + t("required_field")),
    eventDate: yup
      .date()
      .typeError(t("events.date") + t("required_field"))
      .required(),
    eventLocation: yup
      .string()
      .required(t("events.location") + t("required_field")),
    eventType: yup
      .object()
      .transform((value) => (value === null ? {} : value))
      .test(
        "is-eventType-selected",
        t("events.event_type") + t("required_field"),
        (value) => {
          return value && Object.keys(value).length > 0;
        }
      ),
  });
}
