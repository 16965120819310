import { useTranslation } from "react-i18next";
import * as yup from "yup";

export default function ValidateJob() {
  const { t } = useTranslation();

  return yup.object().shape({
    companyName: yup.string().required(t("jobs.company_name") + t("required_field")),
    jobTitle: yup.string().required(t("jobs.title") + t("required_field")),
    jobLocation: yup.string().required(t("jobs.location") + t("required_field")),
    jobURL: yup.string().required(t("jobs.url") + t("required_field")),
  });
}
